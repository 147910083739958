import {Layout} from '@krfts/core-layout'
import {NavBar} from '@krfts/app-navbar'
import {Footer} from '@krfts/app-footer'
import {AuthModal} from "@krfts/app-auth-modal";
import {UnauthCta} from "@krfts/core-unauth-cta";
import {useAuthStore} from "@krfts/app-state";
import {UserAccountContent} from "@krfts/app-user-account-content";
import {useSearchParams} from "react-router";

export const UserAccount = () => {
    const user = useAuthStore((state) => state.currentUser);

    const [searchParams] = useSearchParams()

    return (
        <Layout
            topBar={<NavBar/>}
            page={user ? <UserAccountContent tab={searchParams.get('tab')}/> : <UnauthCta/>}
            footer={<Footer/>}
            authModal={<AuthModal/>}
        />
    )
}