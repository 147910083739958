import {Box, Textarea} from "@chakra-ui/react";

export const Prompt = ({prompt, setPrompt, placeholder, bg}) => {
    return (
        <Box w='full'>
            <Textarea
                bg={bg ? bg : 'gray.700'}
                placeholder={placeholder}
                resize
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
            />
        </Box>
    )
}