import {Box} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getGeneration} from "@krfts/app-studio-content/data/studio-api";
import {GenerationContainer} from "./generation-container";
import {Generation} from "./generation"
import {useProductsStore} from "@krfts/app-products-content/state/products-store";

export const GenerationEntry = ({generationId, studio, isFirst}) => {

    const [childGenerations, setChildGenerations] = useState([])

    const bundleIterations = useProductsStore((state) => state.bundleIterations);

    const {data, isPending} = useQuery({
        queryKey: ['generation', generationId],
        queryFn: () => getGeneration(generationId),
    })

    useEffect(() => {
        if (!data) return

        if (data.child_generations) {
            // console.log(data.child_generations)
            setChildGenerations(data.child_generations)
        }
    }, [data]);

    const renderChildGenerations = () => {
        if (bundleIterations || studio) {
            return (childGenerations.map((childGeneration, i) => <Generation key={i} isChild
                                                                             generationId={childGeneration}
                                                                             studio={studio}/>))
        }
    }

    const getMaxDimensions = () => {
        if (data.format === "1024x1024 (1.0)") return {maxW: 1024, maxH: 1024}
        if (data.format === "1280x768 (1.67)") return {maxW: 1280, maxH: 768}
        if (data.format === "768x1280 (0.6)") return {maxW: 768, maxH: 1280}
    }


    if (isPending) return <></>

    return (
        studio && data.is_iteration ? <></> :
        <Box w='100%' h='100%' maxH={getMaxDimensions().maxH} maxW={getMaxDimensions().maxW}>
            <GenerationContainer studio={studio}>
                <Generation generationId={generationId} studio={studio} isFirst={isFirst}/>
                {renderChildGenerations()}
            </GenerationContainer>
        </Box>
    )
}
