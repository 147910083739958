import {
    FormControl,
    FormLabel,
    Checkbox,
    HStack,
    Input,
    Select,
    Icon,
    Box,
    Stack,
    Text,
    Menu,
    MenuItem,
    MenuButton, IconButton,
    Button,
    MenuList,
} from '@chakra-ui/react'
import {IoLayersOutline, IoChevronDown} from "react-icons/io5";
import {useProductsStore} from "../../../../state/products-store";

export const GridOptions = () => {

    const bundleIterations = useProductsStore((state) => state.bundleIterations);
    const setBundleIterations = useProductsStore((state) => state.setBundleIterations);

    return (
        <Stack direction='row' align='center' spacing={4}>
            <Checkbox
                fontWeight={900}
                colorScheme='yellow'
                defaultChecked={bundleIterations}
                onChange={(e) => setBundleIterations(e.target.checked)}
            >
                Bundle Iterations
            </Checkbox>

            {/*<Menu>*/}
            {/*    <MenuButton rightIcon={<IoChevronDown />} as={Button} variant='ghost' colorScheme='blackAlpha' size='md' color='white'>*/}
            {/*        Filter by:*/}
            {/*    </MenuButton>*/}
            {/*    <MenuList bg='gray.800' border='none'>*/}
            {/*        <MenuItem bg='gray.800' fontSize='md'>All</MenuItem>*/}
            {/*        <MenuItem bg='gray.800' fontSize='md'>With video</MenuItem>*/}
            {/*        <MenuItem bg='gray.800' fontSize='md'>With iterations</MenuItem>*/}
            {/*    </MenuList>*/}
            {/*</Menu>*/}
        </Stack>
    )
}