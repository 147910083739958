import axios from "axios";
import {API_BASE_URL, PRODUCT_ENDPOINT, PRODUCT_ALL_ENDPOINT, GENERATIONS_FOR_PRODUCT_ENDPOINT} from "@krfts/app-common";
import {useAuthStore} from "@krfts/app-state";

export const createProduct = async (productName, productImage, sourceImages, modelImages) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const formData = new FormData();
    // const data = new Blob([JSON.stringify({'name': productName})], { type: "application/json" });
    // formData.append('json', data)
    formData.append("product-name", productName);

    const productImageFilename = "product-image." + productImage.file.name.slice((Math.max(0, productImage.file.name.lastIndexOf(".")) || Infinity) + 1)
    // formData.append("product-image", productImage.file, productImage.file.name)
    formData.append("product-image", productImage.file, productImageFilename)

    sourceImages.map((image, i) => {
        const key = `source-image${i}`
        const sourceImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, sourceImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    modelImages.map((image, i) => {
        const key = `model-image${i}`
        const modelImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, modelImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    try {
        const response = await axios.post(API_BASE_URL + PRODUCT_ENDPOINT, formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getProducts = async () => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + PRODUCT_ALL_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getProduct = async (productId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.get(API_BASE_URL + PRODUCT_ENDPOINT + "/" + productId, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getGenerationsForProduct = async (productId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.get(API_BASE_URL + GENERATIONS_FOR_PRODUCT_ENDPOINT + "/" + productId, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const regenerate3DModel = async (productId) => {
    const uid = await useAuthStore.getState().currentUser.uid
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const data = {
        productId: productId,
        uid: uid,
    }

    try {
        const response = await axios.post(API_BASE_URL + PRODUCT_ENDPOINT + "/retrain", data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}