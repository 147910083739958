import {getDownloadURL, getStorage, ref} from "firebase/storage";

const cachedURLs = {}

export const createDownloadURL = async (file) => {
    if (cachedURLs[file]) {
        // console.log('Returning cached url')
        return cachedURLs[file]
    }

    const storage = getStorage();
    const fileRef = ref(storage, file)
    const url = await getDownloadURL(fileRef)

    cachedURLs[file] = url
    return url
}