export const termsOfUse = {
    lastUpdated: "22 Jan 2025",
    sections: [
        {
            title: "1. Acceptance of Terms",
            content:
                "By accessing and using the services (\"Services\") provided by Imaginate Oy (\"Company\"), including our website, hosted services, software applications, and APIs, you agree to comply with and be bound by these Terms of Use (\"Agreement\"). If you do not agree to these terms, you may not use the Services."
        },
        {
            title: "2. Modifications to the Agreement",
            content:
                "The Company reserves the right to modify this Agreement at any time. Any changes will be posted on our website with an updated 'Last Updated' date. Your continued use of the Services after such modifications constitutes your acceptance of the revised terms."
        },
        {
            title: "3. Use of Services",
            content:
                "Subject to this Agreement, the Company grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Services for your internal business purposes."
        },
        {
            title: "4. User Obligations",
            content:
                "You agree to use the Services in compliance with all applicable laws and regulations, including but not limited to Finnish laws governing data protection and intellectual property."
        },
        {
            title: "5. Intellectual Property Rights",
            content:
                "All intellectual property rights in the Services are owned by the Company or its licensors. You are granted no rights or licenses in respect of the Services or any related documentation, except as expressly provided in this Agreement."
        },
        {
            title: "6. Data Protection",
            content:
                "The Company processes personal data in accordance with its Privacy Policy and applicable Finnish data protection laws. By using the Services, you consent to such processing and warrant that all data provided by you is accurate."
        },
        {
            title: "7. Limitation of Liability",
            content:
                "To the extent permitted by Finnish law, the Company shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Services."
        },
        {
            title: "8. Governing Law and Dispute Resolution",
            content:
                "This Agreement shall be governed by and construed in accordance with the laws of Finland. Any disputes arising out of or in connection with this Agreement shall be resolved by the courts of Finland."
        },
        {
            title: "9. Contact Information",
            content:
                "If you have any questions about this Agreement, please contact us at:\nImaginate Oy\nOrvokkitie 3 B\nHelsinki, Finland\ninfo@imaginate.cc"
        },
        {
            title: "10. Miscellaneous",
            content:
                "If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. The Company's failure to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision."
        }
    ]
};
