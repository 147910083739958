import {Icon, Image, Text, VStack, Box} from '@chakra-ui/react'
import {LoadingIndicator} from "@krfts/core-ui-library/loading-indicator";
import {LoadingTimedProgress} from "@krfts/core-ui-library/loading-timed-progress";
import {IoAlertCircleOutline} from "react-icons/io5";
import {InpaintEditor} from "./inpaint-editor";
import {useStudioStore} from "@krfts/app-studio-content/state";


export const GenerationEntryImage = ({imageURL, isGenerating, studio, isFirst, error, isInpainting}) => {

    return (
        error ?
            <VStack>
                <Icon boxSize='50px' color='red.500'>
                    <IoAlertCircleOutline/>
                </Icon>
                <Text>There was an error generating image, please try again.</Text>
            </VStack>
            :
            (isGenerating ?
                    (studio ?
                            <LoadingTimedProgress message={isFirst ?
                                'Generating Image... (first image will take ~1 minute)' :
                                "Generating Image... (generation will take ~30 seconds)"}
                                                  runTime={isFirst ? 60000 : 30000}/>
                            :
                            <LoadingIndicator color='white' message='Generating image...'/>
                    )

                    :


                    (isInpainting ?
                        <InpaintEditor imageURL={imageURL}/>
                            :
                        <Image
                            src={imageURL}
                            position='absolute'
                            top={0}
                            w='100%'
                            h='100%'
                        />
                    )

                // <Image
                //     src={imageURL}
                //     position='absolute'
                //     top={0}
                //     w='100%'
                //     h='100%'
                // />
            )

    )
}