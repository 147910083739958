import {Routes, Route} from "react-router";
import {Studio} from "./pages/Studio";
import {Dashboard} from "./pages/Dashboard";
import {Products} from "./pages/Products";
import {UserAccount} from "./pages/UserAccount";
import {Home} from "./pages/Home";
import {Landing} from "./pages/Landing";
import {useAuthStore} from "@krfts/app-state";
import {Center, Spinner} from "@chakra-ui/react";

function App() {

    const user = useAuthStore((state) => state.currentUser);
    const loading = useAuthStore((state) => state.loading);
    const betaAuthorized = useAuthStore((state) => state.betaAuthorized);

    if (!user && !betaAuthorized) {
        return <Landing/>
    }

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/products/:id" element={<Products/>}/>
            <Route path="/studio" element={<Studio/>}/>
            <Route path="/account" element={<UserAccount/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/landing" element={<Landing/>}/>
        </Routes>

    );
}

export default App;
