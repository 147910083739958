import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
} from '@chakra-ui/react'
import {useStudioStore} from "../../../state";

export const DetailSlider = () => {

    const bgGuidanceAttentionToDetail = useStudioStore((state) => state.bgGuidanceAttentionToDetail)
    const setBgGuidanceAttentionToDetail = useStudioStore((state) => state.setBgGuidanceAttentionToDetail)

    return(
        <Slider
            aria-label='slider-ex-1'
            defaultValue={bgGuidanceAttentionToDetail ? bgGuidanceAttentionToDetail : 0}
            onChange={(val) => setBgGuidanceAttentionToDetail(val)}>
            <SliderTrack>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={5}>
                <Text align='center' color='black' fontSize={8} fontWeight={900}>{bgGuidanceAttentionToDetail}</Text>
            </SliderThumb>
        </Slider>
    )
}