import {
    Box,
    Image,
    Square,
    VStack,
    Text,
    chakra
} from '@chakra-ui/react'

import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {ProductImage} from "../product-image";
import {RemoveButton} from "@krfts/app-shared-components";

export const ModelImage = ({image, icon, onImageRemoved, onFileDrop, title}) => {
    return (
        image ?
            <Box position='relative'>
                <ProductImage image={image} onImageRemoved={onImageRemoved}/>
                <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                    onImageRemoved(null)
                }}/>
            </Box>
            :
            <AssetDropzone onFileDrop={onFileDrop} assetType={title} boxSize={{base:'150px', md:'100px'}} p={0}>
                <VStack align='center'>
                    <Square size="10">
                        <Image src={icon}/>
                    </Square>
                    <Text align='center' color='black' fontSize='2xs'>Drag / Click to add <chakra.span fontWeight={900}>{title}</chakra.span></Text>
                </VStack>
            </AssetDropzone>
    )
}