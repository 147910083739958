import { create } from 'zustand'

export const useProduct3DViewStore = create((set) => ({

    shouldDisplayTranslateControls: false,
    setShouldDisplayTranslateControls: (shouldDisplay) => set((state) => ({ shouldDisplayTranslateControls: shouldDisplay })),

    shouldDisplayRotateControls: false,
    setShouldDisplayRotateControls: (shouldDisplay) => set((state) => ({ shouldDisplayRotateControls: shouldDisplay })),

    shouldResetTransform: false,
    setShouldResetTransform: (shouldReset) => set((state) => ({ shouldResetTransform: shouldReset })),

    isTransforming: false,
    setIsTransforming: (isTransforming) => set((state) => ({ isTransforming: isTransforming })),

}))