import {
    Box,
    Heading,
    useBreakpointValue,
    Stack,
    Spacer,
} from '@chakra-ui/react'
import Masonry from "react-responsive-masonry"
import {getGenerationsForProduct} from "@krfts/app-data"
import {useQuery} from "@tanstack/react-query";
import {GridOptions} from "./grid-options";
import {ProductGenerationsGridEntry} from "./product-generations-grid-entry";
import {useProductsStore} from "../../../../state/products-store";

export const ProductGenerationsGrid = ({product}) => {

    const bundleIterations = useProductsStore((state) => state.bundleIterations);

    const {isPending, isError, data, error} = useQuery({
        queryKey: ['generationsForProduct', product.id],
        queryFn: () => getGenerationsForProduct(product.id)
    })

    const columnsCount = useBreakpointValue({base: 2, md: 4})

    if (isPending) return null;

    return (
        <Box w='100%' px={4}>
            <Stack>

                <Stack direction='row' spacing={4} align='right'>
                    <Heading size='md'>Generations</Heading>
                    <Spacer/>
                    <GridOptions/>
                </Stack>

                <Box as={Masonry} columnsCount={columnsCount} gutter='8px'>
                    {
                        data.sort((a, b) => new Date(b.created_at._seconds*1000) - new Date(a.created_at._seconds*1000)).map((generation, i) => {
                            if ((!bundleIterations || !generation.is_iteration) && generation.image_generation_data.state !== "ERROR") {
                                return (
                                    <ProductGenerationsGridEntry key={i} generation={generation}/>
                                )
                            }
                        })
                    }
                </Box>
            </Stack>
        </Box>
    )
}