import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    Box,
    Center,
    chakra,
} from '@chakra-ui/react'
import {GoogleIcon} from '../provider-icons'
import {useState} from "react";
import {NavLink} from "react-router";
import {Logo} from "@krfts/app-logo";
import {useAuthStore} from "@krfts/app-state";
import {handleSignIn, handleSignInWithGoogle} from "../../data";

export const SignIn = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal);
    const setAuthModalMode = useAuthStore((state) => state.setAuthModalMode);

    const signInClicked = async () => {
        setLoading(true)
        if (await handleSignIn(email, password)) {
            setShowAuthModal(false)
            setLoading(false)
        }
    }

    const signInWithGoogleClicked = async () => {
        setLoading(true)
        if (await handleSignInWithGoogle()) {
            setShowAuthModal(false)
            setLoading(false)
        }
    }

    return (
        <Center w='100%' h='100%'>
            <Container py={{base: '12', md: '12'}} bg='black' p={8}
                       boxShadow="dark-lg" color='white'>
                <Stack spacing="8">
                    <Stack spacing="6" align="center">
                        <Logo w='250px'/>
                        <Stack spacing={{base: '2', md: '3'}} textAlign="center">
                            <Heading size={{base: 'xs', md: 'sm'}}>Log in to your account</Heading>
                        </Stack>
                    </Stack>
                    <Stack spacing="6" as='form'>
                        <Stack spacing="5">
                            <FormControl isRequired>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" placeholder="Enter your email" type="email" value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Input id="password" placeholder="********" type="password" value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                            </FormControl>
                        </Stack>
                        <HStack justify="space-between">
                            <Checkbox defaultChecked>Remember me</Checkbox>
                            <Button variant="text" size="sm">
                                Forgot password
                            </Button>
                        </HStack>
                        <Stack spacing="4">
                            <Button type='button' disabled={!email || !password} onClick={() => signInClicked()} isLoading={loading}>Sign in</Button>
                            <Button variant="outline" color='white' leftIcon={<GoogleIcon/>} _hover={{ bg: 'gray.700' }}
                                    onClick={() => signInWithGoogleClicked()}
                            >
                                Sign in with Google
                            </Button>
                        </Stack>
                    </Stack>
                    <Text textStyle="sm" color="gray.500" align='center'>
                        Don't have an account? <chakra.span cursor='pointer' textDecoration='underline' onClick={() => setAuthModalMode('signUp')}>Sign up</chakra.span>
                    </Text>
                </Stack>
            </Container>
        </Center>
    )
}