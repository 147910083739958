import {useRef, useEffect, useState} from "react";
import {CameraControls, Outlines, PivotControls, useGLTF} from "@react-three/drei";
import {useStudioStore, useProduct3DViewStore} from "../../../../state";
import {Vector3, Quaternion, Euler, Vector2, Box3} from "three";
import {useFrame, useThree} from "@react-three/fiber";

export const Product3dScene = ({url, setSceneMesh}) => {
    const {nodes} = useGLTF(url);

    const meshRef = useRef()
    const pivotRef = useRef()
    const camControlsRef = useRef()

    const productPosRef = useRef({x: 0, y: 0, z: 0})
    const productRotRef = useRef({x: 0, y: 0, z: 0})

    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)

    const isTransforming = useProduct3DViewStore((state) => state.isTransforming)

    const shouldCaptureControlImages = useStudioStore((state) => state.shouldCaptureControlImages)
    const setShouldCaptureControlImages = useStudioStore((state) => state.setShouldCaptureControlImages)

    const controlImagesCaptured = useStudioStore((state) => state.controlImagesCaptured)
    const setControlImagesCaptured = useStudioStore((state) => state.setControlImagesCaptured)

    const setOutlineImage = useStudioStore((state) => state.setOutlineImage)
    const setDepthImage = useStudioStore((state) => state.setDepthImage)
    const setMaskImage = useStudioStore((state) => state.setMaskImage)

    const setObjectTransform = useStudioStore((state) => state.setObjectTransform)
    const setCameraTransform = useStudioStore((state) => state.setCameraTransform)

    const imageFormat = useStudioStore((state) => state.imageFormat)

    const shouldDisplayTranslateControls = useStudioStore((state) => state.shouldDisplayTranslateControls)
    const shouldDisplayRotateControls = useStudioStore((state) => state.shouldDisplayRotateControls)
    const shouldResetTransform = useStudioStore((state) => state.shouldResetTransform)
    const setShouldResetTransform = useStudioStore((state) => state.setShouldResetTransform)

    const setIsTransforming = useProduct3DViewStore((state) => state.setIsTransforming)

    const {camera, gl, scene, get} = useThree()

    const resetCamera = () => {
        if(camControlsRef.current) camControlsRef.current.fitToBox(nodes.geometry_0, true, {paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1})
    }

    useEffect(() => {
        if (nodes.geometry_0) {
            setSceneMesh(nodes.geometry_0)
            resetCamera()
        }
    }, [nodes]);


    // Reset transforms
    useEffect(() => {
        if (pivotRef.current && shouldResetTransform) {
            const initialRotation = [0, 0, 0]
            const initialPosition = [0, 0, 0]
            pivotRef.current.matrix.makeRotationFromEuler(new Euler(...initialRotation))
            pivotRef.current.matrix.setPosition(...initialPosition)
            productPosRef.current = {x: 0, y: 0, z: 0}
            productRotRef.current = {x: 0, y: 0, z: 0}
            resetCamera()

            setShouldResetTransform(false);
        }
    }, [shouldResetTransform]);

    const [imageState, setImageState] = useState({currentState: "initial", waitForNextFrame: false});

    useEffect(() => {
        camera.fov = 54 // around 35 equivalent
        camera.updateProjectionMatrix()
    }, [])

    useEffect(() => {
        if (lockedGeneration) {
            pivotRef.current.matrix.makeRotationFromEuler(new Euler(
                ...[lockedGeneration.image_generation_data.object_transform.rotation.x,
                    lockedGeneration.image_generation_data.object_transform.rotation.y,
                    lockedGeneration.image_generation_data.object_transform.rotation.z],
            ))
            pivotRef.current.matrix.setPosition(new Vector3(
                ...[lockedGeneration.image_generation_data.object_transform.position.x,
                    lockedGeneration.image_generation_data.object_transform.position.y,
                    lockedGeneration.image_generation_data.object_transform.position.z]
            ))
            productPosRef.current = {
                x: lockedGeneration.image_generation_data.object_transform.position.x,
                y: lockedGeneration.image_generation_data.object_transform.position.y,
                z: lockedGeneration.image_generation_data.object_transform.position.z,
            }
            productRotRef.current = {
                x: lockedGeneration.image_generation_data.object_transform.rotation.x,
                y: lockedGeneration.image_generation_data.object_transform.rotation.y,
                z: lockedGeneration.image_generation_data.object_transform.rotation.z,
            }

            console.log("Obj:", lockedGeneration.image_generation_data.object_transform)
            console.log("Cam:", lockedGeneration.image_generation_data.camera_transform)
            camControlsRef.current.setPosition(
                lockedGeneration.image_generation_data.camera_transform.position.x,
                lockedGeneration.image_generation_data.camera_transform.position.y,
                lockedGeneration.image_generation_data.camera_transform.position.z
            )

            camControlsRef.current.setTarget(
                lockedGeneration.image_generation_data.camera_transform.target.x,
                lockedGeneration.image_generation_data.camera_transform.target.y,
                lockedGeneration.image_generation_data.camera_transform.target.z
            )

            camControlsRef.current.rotateTo(
                lockedGeneration.image_generation_data.camera_transform.rotation.azimuth_angle,
                lockedGeneration.image_generation_data.camera_transform.rotation.polar_angle,
            )

            camControlsRef.current.distance = lockedGeneration.image_generation_data.camera_transform.distance

            camControlsRef.current.setFocalOffset(
                lockedGeneration.image_generation_data.camera_transform.focal_offset.x,
                lockedGeneration.image_generation_data.camera_transform.focal_offset.y,
                lockedGeneration.image_generation_data.camera_transform.focal_offset.z,
            )
        }
    }, [lockedGeneration])

    const captureCanvas = () => {
        const originalSize = new Vector2()
        gl.getSize(originalSize)
        const originalPixelRatio = gl.getPixelRatio()

        if (imageFormat.toLowerCase() === "square") {
            gl.setSize(1024, 1024, false)
        } else if (imageFormat.toLowerCase() === "landscape") {
            gl.setSize(1280, 768, false)
        } else if (imageFormat.toLowerCase() === "portrait") {
            gl.setSize(768, 1280, false)
        }
        gl.setPixelRatio(1)
        gl.render(scene, camera)

        const dataURL = gl.domElement.toDataURL("image/png");

        gl.setSize(originalSize.x, originalSize.y, false);
        gl.setPixelRatio(originalPixelRatio);

        return dataURL;
    }

    const captureMask = () => {
        setMaskImage(captureCanvas());
        // console.log("Mask captured");
        setImageState({currentState: "outline", waitForNextFrame: true}) // Move to the next step
    };

    const captureOutline = () => {
        setOutlineImage(captureCanvas());
        // console.log("Outline captured");
        setImageState({currentState: "depthPrep", waitForNextFrame: true}); // Prep depth step next
    };

    const prepareDepth = () => {
        // console.log("Preparing depth");
        const bbox = new Box3().setFromObject(nodes.geometry_0);
        const longestSide = Math.max(bbox.max.x, bbox.max.z);
        const camPos = camera.position;
        const objectPos = new Vector3(productPosRef.current.x, productPosRef.current.y, productPosRef.current.z);
        const dist = camPos.distanceTo(objectPos);
        camera.near = dist - longestSide * 2.5;
        camera.far = dist + longestSide * 2;
        camera.updateProjectionMatrix();
        setImageState({currentState: "depth", waitForNextFrame: true})
    };

    const captureDepth = () => {
        setDepthImage(captureCanvas());
        // console.log("Depth captured");
        camera.near = 0.1; // Reset original camera settings
        camera.far = 100;
        camera.updateProjectionMatrix();
        setImageState({currentState: "done", waitForNextFrame: false})
    };

    const resetState = () => {
        // console.log("Resetting state");
        setImageState({currentState: "initial", waitForNextFrame: true})
        setShouldCaptureControlImages(false);
    };

    useFrame(() => {
        if (imageState.waitForNextFrame) {
            setImageState({
                ...imageState,
                waitForNextFrame: false
            })
            return
        }

        // console.log(productRot)

        if (shouldCaptureControlImages && !controlImagesCaptured) {
            switch (imageState.currentState) {
                case "initial":
                    setImageState({currentState: "mask", waitForNextFrame: false})
                    setObjectTransform({
                        position: productPosRef.current,
                        rotation: productRotRef.current
                    })
                    const pos = new Vector3()
                    const target = new Vector3()
                    const focalOffset = new Vector3()
                    camControlsRef.current.getPosition(pos)
                    camControlsRef.current.getTarget(target)
                    camControlsRef.current.getFocalOffset(focalOffset)
                    setCameraTransform({
                        position: {
                            x: pos.x,
                            y: pos.y,
                            z: pos.z,
                        },
                        target: {
                            x: target.x,
                            y: target.y,
                            z: target.z,
                        },
                        rotation: {
                            azimuth_angle: camControlsRef.current.azimuthAngle,
                            polar_angle: camControlsRef.current.polarAngle,
                        },
                        focal_offset: {
                            x: focalOffset.x,
                            y: focalOffset.y,
                            z: focalOffset.z,
                        },
                        distance: camControlsRef.current.distance,
                    })
                    break;
                case "mask":
                    captureMask();
                    break;
                case "outline":
                    captureOutline();
                    break;
                case "depthPrep":
                    prepareDepth();
                    break;
                case "depth":
                    captureDepth();
                    break;
                case "done":
                    setControlImagesCaptured(true)
                    resetState();
                    break;
                default:
                    break;
            }
        }
    });



    useEffect(() => {
        if (camControlsRef.current) {
            camControlsRef.current.addEventListener('controlend', () => {
                // console.log(camControlsRef.current.distance)

                camControlsRef.current.setOrbitPoint(productPosRef.current.x, productPosRef.current.y, productPosRef.current.z)
            })
        }
    }, [camControlsRef]);

    return (
        <group>
            <CameraControls
                makeDefault
                ref={camControlsRef}
                enabled={!isTransforming}
                dollySpeed={0}
                truckSpeed={1.25}
                azimuthRotateSpeed={0.5}
                polarRotateSpeed={0.5}
            />

            <PivotControls
                ref={pivotRef}
                anchor={[0, -1, 0]}
                depthTest={false}
                lineWidth={2}
                scale={1}
                autoTransform={true}
                visible={true}
                disableScaling={true}
                disableRotations={!shouldDisplayRotateControls}
                disableAxes={!shouldDisplayTranslateControls}
                disableSliders={!shouldDisplayTranslateControls}
                activeAxes={
                    shouldDisplayTranslateControls ?
                        [shouldDisplayTranslateControls, !shouldDisplayTranslateControls, shouldDisplayTranslateControls]
                        :
                        [true, false, true]
                }
                onDragStart={() => setIsTransforming(true)}
                onDragEnd={() => {
                    setIsTransforming(false)
                    camControlsRef.current.setOrbitPoint(productPosRef.current.x, productPosRef.current.y, productPosRef.current.z)
                }}
                onDrag={(local) => {
                    // keeping track of transforms
                    const position = new Vector3()
                    const scale = new Vector3()
                    const quaternion = new Quaternion()
                    local.decompose(position, quaternion, scale)
                    productPosRef.current = {x: position.x, y: position.y, z: position.z}
                    const euler = new Euler().setFromQuaternion(quaternion)
                    productRotRef.current = {x: euler.x, y: euler.y, z: euler.z}
                }}
            >

                <mesh ref={meshRef} geometry={nodes.geometry_0.geometry} position={[0, 0, 0]}>
                    {!shouldCaptureControlImages ?
                        // <meshStandardMaterial color="white"/>
                        <>
                            <meshBasicMaterial color="black"/>
                            <Outlines thickness={0.006} color="white" screenspace/>
                        </>
                        : null
                    }

                    {shouldCaptureControlImages && imageState.currentState === "mask" ? (
                        <meshBasicMaterial color="white"/>) : null}

                    {shouldCaptureControlImages && imageState.currentState === "outline" ? (
                        <>
                            <meshBasicMaterial color="black"/>
                            <Outlines thickness={0.006} color="white" screenspace/>
                        </>
                    ) : null}

                    {shouldCaptureControlImages && imageState.currentState === "depth" ? <meshDepthMaterial/> : null}
                </mesh>
            </PivotControls>
        </group>
    );
}
