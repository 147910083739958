import {Box, Icon, Image, Square, VStack, HStack, Button, Text, Center, Tooltip, chakra} from "@chakra-ui/react";
import {PiX} from "react-icons/pi";
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {DetailSlider} from "./detail-slider";
import {FiUploadCloud} from 'react-icons/fi'
import {IoHelpCircleOutline} from "react-icons/io5";
import {useStudioStore} from "../../../state";

export const GuidanceImage = ({guidanceImage, setGuidanceImage, onFileDropped}) => {

    return (
        <Box w='full'>
            {guidanceImage ?
                <Box position='relative'>
                    <Image src={guidanceImage} borderRadius="lg"/>
                    <Icon
                        as={PiX}
                        position='absolute'
                        top={-1}
                        right={-1}
                        boxSize='25px'
                        color='white'
                        bg='blackAlpha.700'
                        rounded='full'
                        p={1}
                        cursor='pointer'
                        onClick={() => {
                            setGuidanceImage(null)
                        }}
                        boxShadow='lg'
                    />

                    <VStack rounded='lg' position='absolute' bottom={0} left={0} w='100%' spacing="2" p={2} px={4} align='left'
                            bgGradient='linear(to-b, transparent, blackAlpha.700)'>
                        <Text align='left' fontSize='xs'>
                            Attention to detail:
                            <Tooltip
                                placement='top'
                                label='Instructs the AI how much detail to include from the provided image.
                                Generally, lower values work better for abstract images and higher values are better for realistic images.'
                            >
                                <chakra.span verticalAlign='super'>
                                    <Icon as={IoHelpCircleOutline} boxSize="4" color="fg.muted"/>
                                </chakra.span>
                            </Tooltip>
                        </Text>
                        <DetailSlider/>
                    </VStack>

                </Box>
                :
                <AssetDropzone onFileDrop={onFileDropped} assetType='metadata' bg='gray.700' rounded='lg' ratio={2}>

                    <Center>
                        <VStack spacing="2">
                            <Text textAlign='center' fontSize='sm' color='gray.200' p={2}>Add an image to inspire the
                                setting of the product</Text>
                            <Square size="10" borderRadius="lg">
                                <Icon as={FiUploadCloud} boxSize="5" color="fg.muted"/>
                            </Square>
                            <VStack spacing="1">
                                <HStack spacing="1" whiteSpace="nowrap">
                                    <Button variant="text" colorScheme="blue" size="sm" fontSize='sm' p={0}>
                                        Click to upload
                                    </Button>
                                    <Text textStyle="sm" color="gray.200" fontSize='sm'>
                                        or drag and drop
                                    </Text>
                                </HStack>
                                <Text textStyle="xs" color="gray.200" fontSize='sm'>
                                    PNG, JPG or WEBP
                                </Text>
                            </VStack>
                        </VStack>
                    </Center>

                </AssetDropzone>
            }
        </Box>
    )
}