import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ChakraProvider, CSSReset, extendTheme} from '@chakra-ui/react'
import {BrowserRouter} from "react-router"
import {initializeApp} from "firebase/app";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'


import {firebaseConfig} from "@krfts/app-common"

// import { getAnalytics } from "firebase/analytics";

import '@fontsource/open-sans'
import '@fontsource/raleway'

const theme = extendTheme({
    fonts: {
        heading: `'Raleway', sans-serif`,
        body: `'Open Sans', sans-serif`,
    },
})

export const queryClient = new QueryClient()

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ChakraProvider theme={theme}>
            <CSSReset/>
            <QueryClientProvider client={queryClient}>
                <App/>
            </QueryClientProvider>
        </ChakraProvider>
    </BrowserRouter>
);