import {
    AspectRatio,
    Box,
    VStack,
    Image,
    Stack,
    Text,
    Spacer,
    Tooltip,
} from '@chakra-ui/react'
import {useNavigate} from "react-router";
import {GenerationsCount} from "@krfts/app-shared-components";
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {StatusBadge} from "../product-details/product-data/product-state/status-badge";

export const ProductCard = (props) => {

    const [productImageURL, setProductImageURL] = useState(null)

    const navigate = useNavigate()
    const {product, maxW, fontSize, p, hideInfo} = props

    useEffect(() => {
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(product.product_image.file))
        }
        getProductImageURL()
    }, [])

    return (
        <Stack direction='column' spacing="4" cursor='pointer' maxW={maxW || '200px'} onClick={() => {
            navigate(product.id)
        }}>
            <Box bg='black' p={p || 6}>

                <Stack direction='column' spacing={4}>
                    <AspectRatio ratio={1}>
                        <Image
                            src={productImageURL}
                            alt={product.name}
                            draggable="false"
                        />
                    </AspectRatio>

                    <VStack align='left'>
                        <Tooltip label={product.name} placement='top'>
                            <Text
                                color='white'
                                fontSize={fontSize || "2xs"}
                                fontWeight="semibold"
                                letterSpacing="wider"
                                textTransform="uppercase"
                                isTruncated
                            >
                                {product.name}
                            </Text>
                        </Tooltip>

                        {hideInfo ? null : <Stack direction='row' w='100%'>
                            <GenerationsCount product={product}/>
                            <Spacer/>
                            <StatusBadge modelStatus={product.model_3D_generation.status}
                                         loraStatus={product.lora_training.status} size='2xs'/>
                        </Stack>}

                    </VStack>
                </Stack>

            </Box>
        </Stack>
    )
}