import {createUser} from "./auth-api";
import {useAuthStore} from "@krfts/app-state";


export const handleSignIn = async (email, password) => {
    try {
        await useAuthStore.getState().signIn({email, password})
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleSignInWithGoogle = async () => {
    try {
        await useAuthStore.getState().signInWithGoogle()
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleSignUp = async (email, password) => {
    const authStore = useAuthStore.getState()
    try {
        await authStore.signUp({email, password})
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}