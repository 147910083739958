import {Stack} from '@chakra-ui/react'
import {PlanCard} from "../plan-card";
import {priceIds} from "@krfts/app-common/stripe-config";

export const MonthlyPlans = ({user, selectedPlan}) => {
    return (
        <Stack direction={{base:'column', md:'row'}} spacing={4} justifyContent='space-between'>
            <PlanCard
                title='Starter'
                price='$25'
                priceId={priceIds.starter.monthly}
                active={selectedPlan === priceIds.starter.monthly}
                noSub={!selectedPlan}
                features={[
                    "125 monthly credits",
                    "Unlimited products",
                    "Removed watermarks"
                ]}
                user={user}
            />
            <PlanCard
                title='Standard'
                price='$50'
                priceId={priceIds.standard.monthly}
                active={selectedPlan === priceIds.standard.monthly}
                noSub={!selectedPlan}
                features={[
                    "275 monthly credits",
                    "Unlimited products",
                    "Removed watermarks"
                ]}
                user={user}
            />
            <PlanCard
                title='Pro'
                price='$100'
                priceId={priceIds.pro.monthly}
                active={selectedPlan === priceIds.pro.monthly}
                noSub={!selectedPlan}
                features={[
                    "650 monthly credits",
                    "Unlimited products",
                    "Removed watermarks",
                    "Support contact",
                    "Discounted add'l credits"
                ]}
                user={user}
            />
        </Stack>
    )
}