import {ButtonGroup, IconButton, Tooltip, Stack, Spacer, Box, Text, Center, HStack, Icon} from "@chakra-ui/react";
import {useStudioStore} from "../../../state";
import {LuMove3D, LuRotate3D, LuCircleDot} from "react-icons/lu";
import {TbHandFinger, TbHandTwoFingers} from "react-icons/tb";
import {isMobile} from "react-device-detect";

export const TransformControls = () => {

    const shouldDisplayTranslateControls = useStudioStore((state) => state.shouldDisplayTranslateControls)
    const setShouldDisplayTranslateControls = useStudioStore((state) => state.setShouldDisplayTranslateControls)
    const shouldDisplayRotateControls = useStudioStore((state) => state.shouldDisplayRotateControls)
    const setShouldDisplayRotateControls = useStudioStore((state) => state.setShouldDisplayRotateControls)
    const setShouldResetTransform = useStudioStore((state) => state.setShouldResetTransform)

    return (
        <>
            <Stack direction='column' spacing={2} align='center' w='100%'>
                <Stack direction='row' w='100%' align='center' justify='center'>
                    <Box>
                        <ButtonGroup isAttached>
                            <Tooltip label='Move'>
                                <IconButton
                                    bg='transparent'
                                    _hover={shouldDisplayTranslateControls ? {color: 'white'} : {color: 'gray.300'}}
                                    _active={{}}
                                    color={shouldDisplayTranslateControls ? 'white' : 'gray.500'}
                                    aria-label='Move'
                                    icon={<LuMove3D/>}
                                    onClick={() => {
                                        setShouldDisplayTranslateControls(!shouldDisplayTranslateControls)
                                        setShouldDisplayRotateControls(false)
                                    }}
                                />
                            </Tooltip>
                            <Tooltip label='Rotate'>
                                <IconButton
                                    bg='transparent'
                                    _hover={shouldDisplayRotateControls ? {color: 'white'} : {color: 'gray.300'}}
                                    _active={{}}
                                    color={shouldDisplayRotateControls ? 'white' : 'gray.500'}
                                    aria-label='Rotate'
                                    icon={<LuRotate3D/>}
                                    onClick={() => {
                                        setShouldDisplayRotateControls(!shouldDisplayRotateControls)
                                        setShouldDisplayTranslateControls(false)
                                    }}
                                />
                            </Tooltip>
                        </ButtonGroup>
                        <Tooltip label='Reset'>
                            <IconButton
                                float='right'
                                bg='transparent'
                                _hover={{color: 'white'}}
                                _active={{}}
                                color='gray.300'
                                aria-label='Reset'
                                icon={<LuCircleDot/>}
                                onClick={() => {
                                    setShouldResetTransform(true)
                                }}
                            />
                        </Tooltip>
                    </Box>
                </Stack>


                <Stack direction='row'>
                    <Center>
                        {isMobile ?
                            <HStack spacing={0.5}>
                                <Icon color='gray.500' as={TbHandFinger}/>
                                <Text color='gray.500' fontSize='xs'>Orbit</Text>
                            </HStack>
                            :
                            <Text color='gray.500' fontSize='xs'>[LMB] Orbit</Text>
                        }
                    </Center>
                    <Center>
                        {isMobile ?
                            <HStack spacing={0.5}>
                                <Icon color='gray.500' as={TbHandTwoFingers}/>
                                <Text color='gray.500' fontSize='xs'>Pan</Text>
                            </HStack>
                            :
                            <Text color='gray.500' fontSize='xs'>[RMB] Pan</Text>
                        }
                    </Center>

                </Stack>

            </Stack>
        </>
    )
}
