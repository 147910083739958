import {Box, Image, Center, Flex} from "@chakra-ui/react";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import image1 from './assets/image1.jpg'
import image2 from './assets/image2.jpg'
import image3 from './assets/image3.jpg'
import image4 from './assets/image4.jpg'
import image5 from './assets/image5.jpg'
import image6 from './assets/image6.jpg'
import image7 from './assets/image7.jpg'
import image8 from './assets/image8.jpg'
import image10 from './assets/image10.jpg'
import image11 from './assets/image11.jpg'
import image12 from './assets/image12.jpg'
import image13 from './assets/image13.jpg'

export const BgCarousel = () => {
    return (

        <Flex
            flex={1}
            position="absolute"
            height="calc(100vh - 227px)"
            insetX="0"
            insetY={{base:"56px", md:"56px"}}
            w="full"
            overflow="hidden"
            align="center"
            zIndex={-1}
            bg='#101214'
        >

                    <Box
                        as={Splide}
                        options={{
                            type: "loop",
                            autoScroll: {
                                pauseOnHover: false,
                                pauseOnFocus: false,
                                rewind: true,
                                speed: 1
                            },
                            arrows: false,
                            pagination: false,
                            gap: '0px',
                            width: '100%',
                            autoWidth: true,
                        }}
                        extensions={{AutoScroll}} // Use the AutoScroll extension
                    >
                        <SplideSlide>
                            <Image src={image1}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image13}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image2}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image12}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image3}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image11}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image4}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image10}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image5}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image6}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image8}/>
                        </SplideSlide>
                        <SplideSlide>
                            <Image src={image7}/>
                        </SplideSlide>
                    </Box>

            <Box position='absolute' bg='blackAlpha.400' top={0} w='full' h='full'></Box>
        </Flex>
    );
}
