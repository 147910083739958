import {
    Box, Button,
    Center, Icon,
    Text, Tooltip, VStack,
} from '@chakra-ui/react'
import {IoAlertCircleOutline} from "react-icons/io5";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {Prompt} from "@krfts/core-ui-library";
import {useEffect, useState} from "react";
import {costs} from "@krfts/app-common";
import {useQuery} from "@tanstack/react-query";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";
import {useAuthStore} from "@krfts/app-state";

export const GenerationEntryVideo = ({
                                         imageURL,
                                         videoURL,
                                         videoPrompt,
                                         setVideoPrompt,
                                         onGenerateVideoClicked,
                                         isGenerating,
                                         isSubmitting,
                                         error
                                     }) => {


    /// Check if enough credits -- this needs to be put somewhere in common
    const currentUser = useAuthStore((state) => state.currentUser)
    const [hasEnoughCredits, setHasEnoughCredits] = useState(false)
    const {data} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })
    useEffect(() => {
        if (data) {
            const totalCredits = data.credits.purchased_credits + data.credits.plan_credits
            setHasEnoughCredits(totalCredits >= costs.video)
        }
    }, [data]);


    return (
        videoURL ?
            <Box
                position='absolute'
                top={0}
                w='100%'
                h='100%'
                as='video'
                muted
                autoPlay
                controls
                src={videoURL}
                bg='black'
                loop
            />
            :

            <Box boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.8)' bgImage={imageURL} bgSize='cover'
                 position='absolute'
                 top={0}
                 w='100%'
                 h='100%'
            >
                <Center h='100%' color='white'>
                    {isGenerating ?
                        <LoadingIndicator color='white' message='Generating video...'/>
                        :
                        <VStack spacing={10} w='65%'>
                            <VStack gap={0}>
                                {error ? <Icon boxSize='50px' color='red.500'>
                                    <IoAlertCircleOutline/>
                                </Icon> : null}
                                <Text>
                                    {error ? 'There was an error generating video, please try again.' : 'No existing video found.'}
                                </Text>
                            </VStack>

                            <VStack gap={4} w='100%'>
                                <Text>Generate a video from the image:</Text>
                                <Prompt
                                    placeholder='Describe briefly any desired camera moves, themes etc. Leave empty for default style.'
                                    prompt={videoPrompt}
                                    setPrompt={setVideoPrompt}
                                    bg='gray.700'
                                />
                                <Tooltip
                                    label={hasEnoughCredits ?
                                        ''
                                        :
                                        "You don't have enough credits to generate a video. Please purchase more credits."
                                    }
                                    placement='top'
                                >
                                    <Box bg='transparent' border='4px' borderColor='white' rounded='full' p={0.5}
                                         w='60%'>
                                        <Button
                                            isDisabled={!hasEnoughCredits}
                                            border='2px'
                                            borderColor='white'
                                            color='white'
                                            w='100%'
                                            size='lg'
                                            rounded='full'
                                            bg="#101214"
                                            _hover={{bgGradient: "linear(to-r, pink.500, purple.500)"}}
                                            onClick={onGenerateVideoClicked}
                                        >
                                            <Text bgGradient="linear(to-r, yellow.300, pink.300)" bgClip='text'
                                                  fontWeight={900}>IMAGINATE!</Text>
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </VStack>
                        </VStack>}
                </Center>
            </Box>
    )
}