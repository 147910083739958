import axios from "axios";
import {
    API_BASE_URL,
    USER_ENDPOINT,
    USER_STRIPE_BILLING_SESSION_ENDPOINT,
    USER_STRIPE_CUSTOMER_SESSION_ENDPOINT,
    USER_STRIPE_CHECK_CUSTOMER_SUBSCRIPTION,
    USER_STRIPE_CREATE_CHECKOUT_SESSION, USER_STRIPE_CREATE_CREDIT_CHECKOUT_SESSION
} from "@krfts/app-common";
import {useAuthStore} from "@krfts/app-state";

export const createUser = async (user) => {
    const idToken = user.accessToken
    try {
        const response = await axios.post(API_BASE_URL + USER_ENDPOINT, {uid: user.uid, email: user.email}, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getUser = async (user) => {
    const idToken = user.accessToken
    try {
        const response = await axios.get(API_BASE_URL + USER_ENDPOINT + "/" + user.uid, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const userExists = async (user) => {
    const idToken = user.accessToken

    try {
        const response = await axios.get(API_BASE_URL + USER_ENDPOINT + "/" + user.uid, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getStripeBillingSessionUrl = async (user) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.post(API_BASE_URL + USER_STRIPE_BILLING_SESSION_ENDPOINT, {stripe_id: user.stripe_id}, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        console.log(response)
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getStripeCustomerSessionUrl = async (user) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.post(API_BASE_URL + USER_STRIPE_CUSTOMER_SESSION_ENDPOINT, {stripe_id: user.stripe_id}, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        console.log(response)
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const checkStripeCustomerSubscriptionStatus = async (user) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.post(API_BASE_URL + USER_STRIPE_CHECK_CUSTOMER_SUBSCRIPTION, {stripe_id: user.stripe_id}, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        console.log(response)
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const createStripeSubscriptionCheckoutSession = async (user, priceId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.post(API_BASE_URL + USER_STRIPE_CREATE_CHECKOUT_SESSION,
            {stripe_id: user.stripe_id, price_id: priceId},
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            })
        console.log(response)
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const createStripeCreditsCheckoutSession = async (user, priceId, creditsUnits) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.post(API_BASE_URL + USER_STRIPE_CREATE_CREDIT_CHECKOUT_SESSION,
            {stripe_id: user.stripe_id, price_id: priceId, quantity: creditsUnits},
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            })
        console.log(response)
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}