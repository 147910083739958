import {getApps, initializeApp} from 'firebase/app';
import {firebaseConfig} from "@krfts/app-common"
import {create} from 'zustand'
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    getRedirectResult
} from "firebase/auth";
import {getUser, userExists, createUser} from "@krfts/app-auth-modal/data/auth-api";
import {  isMobile } from 'react-device-detect';

if (getApps().length === 0) {
    initializeApp(firebaseConfig)
}

let auth = getAuth()
const provider = new GoogleAuthProvider();

export const useAuthStore = create((set) => ({
    currentUser: null,
    signInError: "",
    signUpError: "",
    signOutError: "",
    loading: true,
    showAuthModal: false,
    authModalMode: "signIn",

    betaAuthorized: false,
    setBetaAuthorized: (val) => {
        set({betaAuthorized: val})
    },

    setShowAuthModal: (val) => {
        set({showAuthModal: val})
    },
    setAuthModalMode: (val) => {
        set({authModalMode: val})
    },
    signIn: async (credentials) => {
        const {email, password} = credentials
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            set({signInError: error.message})
        }
    },
    signInWithGoogle: async () => {
        try {
            let response
            if (isMobile) {
                response = signInWithRedirect(auth, provider)
                // response = await getRedirectResult(auth)
            } else {
                // response = signInWithRedirect(auth, provider)
                response = await signInWithPopup(auth, provider)
            }

            if (!await userExists(response.user)) {
                console.log("user does not exist, creating...")
                await createUser(response.user)
            }

        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
        }
    },
    signUp: async (credentials) => {
        const {email, password} = credentials
        console.log("signing up with: ", email, password)
        try {
            const response = await createUserWithEmailAndPassword(auth, email, password)
            console.log("user at sign up:", response.user)
            await createUser(response.user)

            // 'sign in' the user so the updated profile data gets refreshed
            await signOut(auth)
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            console.log(error)
            set({signUpError: error.message})
        }
    },
    signOut: async () => {
        try {
            await signOut(auth)
        } catch (error) {
            set({signOutError: error.message})
        }
    }
}))

onAuthStateChanged(auth, async (currentUser) => {
    // console.log("AuthStateChanged: ", currentUser)

    if (currentUser) {
        // const response = await getUser(currentUser)
        // currentUser.krfts = response.data

        console.log("AuthStateChanged: ", currentUser)

        useAuthStore.setState({
            currentUser: currentUser,
            loading: false
        })
    } else {
        console.log("AuthStateChanged: ", currentUser)
        useAuthStore.setState({
            currentUser: null,
            loading: false
        })
    }
})