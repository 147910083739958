import {Box, Center, Spacer, HStack, Stack, Container, Button} from '@chakra-ui/react'
import {Logo} from "@krfts/app-logo"
import {UserMenu} from "@krfts/app-user-menu"
import {NavButtons} from "./nav-buttons"
import {MobileDrawer} from "./mobile-drawer"
import {useAuthStore} from "@krfts/app-state";

export const NavBar = () => {

    const currentUser = useAuthStore((state) => state.currentUser);
    const authLoading = useAuthStore((state) => state.loading);
    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal);
    const setAuthModalMode = useAuthStore((state) => state.setAuthModalMode);

    return (
        <Box as="section" w='100%' >
            <Box borderBottomWidth="0px" bg="#101214" position="relative" color='white' w='100%' boxShadow="lg" >
                <Container py="2" px='12' minW='80%' >
                    <Stack direction='row' justify="space-between" spacing={{base: 3, md: 20}} w='100%' >
                        <Center>
                            <MobileDrawer/>
                        </Center>
                        <Center>
                            <Box w='150px'>
                                <Center>
                                    <Logo w={{base: '100px', md: '150px'}}/>
                                </Center>
                            </Box>
                        </Center>
                        <HStack spacing="10">
                            <NavButtons/>
                        </HStack>
                        <Spacer/>
                        <HStack spacing={{base: '2', md: '4'}}>
                            {currentUser ?
                                <UserMenu/>
                                :
                                <Button
                                    size='sm'
                                    bg='yellow.300'
                                    _hover={{bg: 'yellow.100'}}
                                    onClick={() => {
                                        setAuthModalMode('signIn')
                                        setShowAuthModal(true)
                                    }}
                                    isLoading={authLoading}
                                >
                                    Sign In
                                </Button>
                            }
                        </HStack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}