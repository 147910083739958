import {
    Box,
    Heading,
    Button,
    Stack,
    HStack,
    Center,
} from "@chakra-ui/react";
import {TextBox} from "@krfts/core-text-box";
import {BgCarousel} from './bg-carousel'
import {useAuthStore} from "@krfts/app-state";
import {useNavigate} from "react-router";

export const HomeContent = () => {

    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal)
    const currentUser = useAuthStore((state) => state.currentUser)

    const navigate = useNavigate()

    return (
        <>
            <Center color='white'>
                <Stack direction='column' px={{base:10, md:40}}>
                    <Heading
                        size={{base:"xl", md:"4xl"}}
                        bg="black"
                        p={4}
                        px={{base:6, md:6}}
                        pt={5}
                        pb={3}
                        fontSize="75">
                        Your Product. Virtually Anywhere.
                    </Heading>
                    <HStack>
                        <Box mt="10" w={{base:'95%', md:'60%'}}>
                            <TextBox
                                text='Create stunning, AI-crafted visuals and videos of your products in any setting,
                             bringing your ideas to life and making the impossible beautifully real.'
                            />
                        </Box>
                    </HStack>

                    <Stack
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                        mt="10"
                        spacing="4">
                        <Button
                            as="a"
                            href="#"
                            colorScheme="pink"
                            bgGradient="linear(to-r, pink.600, purple.600)"
                            _hover={{bgGradient: "linear(to-r, pink.400, purple.400)"}}
                            px="8"
                            size="lg"
                            fontSize="md"
                            fontWeight="bold"
                            onClick={() => {
                                if (currentUser) navigate('/studio')
                                else setShowAuthModal(true)
                            }}
                        >
                            {currentUser ? "Open Studio" : "Get Started"}
                        </Button>
                    </Stack>
                </Stack>

            </Center>
            <BgCarousel/>
        </>
    );
};