import {Layout} from '@krfts/core-layout'
import {NavBar} from '@krfts/app-navbar'
import {Footer} from '@krfts/app-footer'
import {HomeContent} from '@krfts/app-home-content'
import {AuthModal} from "@krfts/app-auth-modal";

export const Home = () => {
    return (
        <Layout
            topBar={<NavBar/>}
            page={<HomeContent/>}
            footer={<Footer/>}
            authModal={<AuthModal/>}
        />
    )
}