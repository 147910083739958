import { Box, Button, HStack, Heading, Icon, List, ListItem, Stack, Text, Grid, GridItem } from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'
import {createStripeSubscriptionCheckoutSession, getStripeBillingSessionUrl} from "@krfts/app-auth-modal/data/auth-api";
import {useState} from "react";

export const PlanCard = ({title, price, features, priceId, user, active, noSub}) => {

    const [loading, setLoading] = useState(false)

    return (
        <Box
            borderWidth="2px"
            borderRadius="none"
            px={{ base: '6', md: '8' }}
            py="8"
            maxW={{base:"lg", md:"xs"}}
            bg='black'
            color='white'
        >
            <Stack spacing={{ base: '10', md: '10' }} textAlign="center">
                <Stack align="center" spacing={4}>
                    <Text textStyle={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                        {title}
                    </Text>
                    <Heading size={{ base: 'md', md: 'lg' }}>{price}</Heading>
                    <Button
                        bg='yellow.300'
                        _hover={{ bg: 'yellow.100' }}
                        w='100%'
                        isLoading={loading}
                        onClick={async () => {
                            setLoading(true)

                            let result
                            if (noSub) {
                                result = await createStripeSubscriptionCheckoutSession(user, priceId)
                            } else {
                                result = await getStripeBillingSessionUrl(user)
                            }

                            window.location.href = result.data.url
                            // setLoading(false)
                        }}
                        disabled={active}
                    >
                        {active ? 'Current plan' : `Select ${title}`}
                    </Button>
                </Stack>
                <Grid templateColumns={{base:'repeat(2, 1fr)', md:'repeat(1, 1fr)'}} gap={2}>
                    {features.map((feature) => (
                        <GridItem key={feature} color="fg.muted">
                            <HStack spacing="2">
                                <Icon as={FiCheck} color="accent" boxSize="3" />
                                <Text fontSize='xs'>{feature}</Text>
                            </HStack>
                        </GridItem>
                    ))}
                </Grid>
                {/*<List spacing="2">*/}
                {/*    {features.map((feature) => (*/}
                {/*        <ListItem key={feature} color="fg.muted">*/}
                {/*            <HStack spacing="2">*/}
                {/*                <Icon as={FiCheck} color="accent" boxSize="3" />*/}
                {/*                <Text fontSize='xs'>{feature}</Text>*/}
                {/*            </HStack>*/}
                {/*        </ListItem>*/}
                {/*    ))}*/}
                {/*</List>*/}
            </Stack>
        </Box>
    )
}