import {
    Box,
    Image,
    Square,
    VStack,
    Text,
    Stack
} from '@chakra-ui/react'

import {ModelImage} from "./model-image";
import leftView from "../../../assets/left-view.png";
import rightView from "../../../assets/right-view.png";
import frontView from "../../../assets/front-view.png";
import backView from "../../../assets/back-view.png";
import topView from "../../../assets/top-view.png";

export const ModelImages = (props) => {
    return (
        <VStack spacing={6}>
            <Text w={{base:'100%', md:'80%'}} fontSize={{base:'xs', md:'sm'}} align='center'>
                Add square images of the product from various angles - they don't need to be exact, but try to cover all sides
                of the product. Keep the background as simple as possible.
            </Text>

            <Stack direction={{base:'column', md:'row'}} spacing={{base:"2", md:"4"}}>
                <ModelImage
                    image={props.modelImageLeft}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageLeft}
                    title='Left View'
                    icon={leftView}
                />
                <ModelImage
                    image={props.modelImageRight}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageRight}
                    title='Right View'
                    icon={rightView}
                />
                <ModelImage
                    image={props.modelImageFront}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageFront}
                    title='Front View'
                    icon={frontView}
                />
                <ModelImage
                    image={props.modelImageBack}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageBack}
                    title='Back View'
                    icon={backView}
                />
                <ModelImage
                    image={props.modelImageTop}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageTop}
                    title='Top View'
                    icon={topView}
                />
            </Stack>
        </VStack>
    )
}