import { create } from 'zustand'

export const useStudioStore = create((set) => ({

    selectedProduct: null,
    setSelectedProduct: (product) => set((state) => ({
        shouldDisplayTranslateControls: false,
        shouldDisplayRotateControls: false,
        selectedProduct: product,
    })),

    imageFormat: "square",
    setImageFormat: (format) => set((state) => ({ imageFormat: format })),

    productPrompt: "",
    setProductPrompt: (prompt) => set((state) => ({ productPrompt: prompt })),

    bgPrompt: "",
    setBgPrompt: (prompt) => set((state) => ({ bgPrompt: prompt })),

    bgGuidanceImage: null,
    setBgGuidanceImage: (image) => set((state) => ({ bgGuidanceImage: image })),

    bgGuidanceAttentionToDetail: 0,
    setBgGuidanceAttentionToDetail: (value) => set((state) => ({ bgGuidanceAttentionToDetail: value })),

    outlineImage: null,
    setOutlineImage: (image) => set((state) => ({ outlineImage: image })),

    depthImage: null,
    setDepthImage: (image) => set((state) => ({ depthImage: image })),

    maskImage: null,
    setMaskImage: (image) => set((state) => ({ maskImage: image })),

    inpaintingMaskImage: null,
    setInpaintingMaskImage: (image) => set((state) => ({ inpaintingMaskImage: image })),

    objectTransform: { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 } },
    setObjectTransform: (transform) => set((state) => ({ objectTransform: transform })),

    cameraTransform: { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 } },
    setCameraTransform: (transform) => set((state) => ({ cameraTransform: transform })),

    // for tweaking a particular image, maybe creating a collection of image variations?
    lockedGeneration: null,
    setLockedGeneration: (generation) => set((state) => ({ lockedGeneration: generation })),

    shouldDisplayLockedGeneration: false,
    setShouldDisplayLockedGeneration: (shouldDisplay) => set((state) => ({ shouldDisplayLockedGeneration: shouldDisplay })),

    generationCurrentlyRunning: null,
    setGenerationCurrentlyRunning: (generation) => set((state) => ({ generationCurrentlyRunning: generation })),

    inpaintingImage: false,
    setInpaintingImage: (inpainting) => set((state) => ({ inpaintingImage: inpainting })),

    shouldCaptureControlImages: false,
    setShouldCaptureControlImages: (shouldCapture) => set((state) => ({
        shouldDisplayTranslateControls: false,
        shouldDisplayRotateControls: false,
        shouldCaptureControlImages: shouldCapture
    })),

    shouldCaptureInpaintingMask: false,
    setShouldCaptureInpaintingMask: (shouldCapture) => set((state) => ({ shouldCaptureInpaintingMask: shouldCapture })),

    shouldDisplayTranslateControls: false,
    setShouldDisplayTranslateControls: (shouldDisplay) => set((state) => ({ shouldDisplayTranslateControls: shouldDisplay })),

    shouldDisplayRotateControls: false,
    setShouldDisplayRotateControls: (shouldDisplay) => set((state) => ({ shouldDisplayRotateControls: shouldDisplay })),

    shouldResetTransform: false,
    setShouldResetTransform: (shouldReset) => set((state) => ({ shouldResetTransform: shouldReset })),

    controlImagesCaptured: false,
    setControlImagesCaptured: (captured) => set((state) => ({ controlImagesCaptured: captured })),

    inpaintingMaskCaptured: false,
    setInpaintingMaskCaptured: (captured) => set((state) => ({ inpaintingMaskCaptured: captured })),

    shouldGenerateImage: false,
    setShouldGenerateImage: (shouldGenerate) => set((state) => ({ shouldGenerateImage: shouldGenerate })),

    currentSessionGenerations: [],
    addToCurrentSessionGenerations: (generation) => set((state) => ({ currentSessionGenerations: [...state.currentSessionGenerations, generation] })),
}))