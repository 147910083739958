import {
    Box,
    Stack,
    Spacer,
    IconButton,
    Tooltip,
} from '@chakra-ui/react'
import {useStudioStore} from "@krfts/app-studio-content/state";
import {useNavigate} from "react-router";
import {
    IoFilmOutline,
    IoImageOutline,
    IoDownloadOutline,
    IoColorPaletteOutline,
    IoBrushOutline,
    IoCreateOutline,
    IoDocumentLockOutline
} from "react-icons/io5";
import {useProductsStore} from "@krfts/app-products-content/state/products-store";


export const GenerationEntryToolbar = ({media, onSetMedia, onDownload, studio, generation}) => {

    const addToCurrentSessionGenerations = useStudioStore((state) => state.addToCurrentSessionGenerations);

    const lockedGeneration = useStudioStore((state) => state.lockedGeneration);
    const setLockedGeneration = useStudioStore((state) => state.setLockedGeneration);

    const inpaintingImage = useStudioStore((state) => state.inpaintingImage);
    const setInpaintingImage = useStudioStore((state) => state.setInpaintingImage);

    const setShowGenerationsDisplayModal = useProductsStore((state) => state.setShowGenerationsDisplayModal);

    const navigate = useNavigate();

    return (
        <Box
            bgGradient='linear(to-t, transparent, blackAlpha.700)'
            position='absolute'
            top={0}
            left={0}
            w='100%'
            h='50px'
        >
            <Stack direction='row' gap={0} top={0} position='absolute' w='100%' p={1}>
                <Tooltip label='View image'>
                    <IconButton
                        aria-label='image'
                        size='md'
                        color={media === "image" ? "white" : "whiteAlpha.300"}
                        variant='ghost'
                        _hover={{color: 'white'}}
                        _active={{}}
                        onClick={() => {
                            onSetMedia("image")
                        }}
                    >
                        <IoImageOutline size='25'/>
                    </IconButton>
                </Tooltip>
                <Tooltip label='View / Generate video'>
                    <IconButton
                        aria-label='video'
                        size='md'
                        color={media === "video" ? "white" : "whiteAlpha.300"}
                        variant='ghost'
                        _hover={{color: 'white'}}
                        _active={{}}
                        onClick={() => {
                            onSetMedia("video")
                        }}
                    >
                        <IoFilmOutline size='25'/>
                    </IconButton>
                </Tooltip>

                <Spacer/>

                {
                    studio && lockedGeneration && (lockedGeneration.id === generation.id) ?
                        <Tooltip label='Affect only painted area'>
                            <IconButton
                                aria-label='inpaint'
                                mt='2px'
                                size='md'
                                // color='white'
                                color={inpaintingImage ? "white" : "whiteAlpha.300"}
                                variant='ghost'
                                _hover={{color: 'white'}}
                                _active={{}}
                                onClick={() => {
                                    setInpaintingImage(!inpaintingImage)
                                }}
                            >
                                <IoBrushOutline size='25'/>
                            </IconButton>
                        </Tooltip>
                        : null
                }


                {!studio ?
                    <Tooltip label='Open in Studio'>
                        <IconButton
                            aria-label='iterate'
                            mt='2px'
                            size='md'
                            color='white'
                            variant='ghost'
                            _hover={{color: 'white'}}
                            _active={{}}
                            onClick={() => {
                                addToCurrentSessionGenerations(generation.id)
                                setShowGenerationsDisplayModal(false)
                                setLockedGeneration(null)
                                navigate("/studio")
                            }}
                        >
                            <IoCreateOutline size='25'/>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip label='Create Iterations'>
                        <IconButton
                            aria-label='iterate'
                            mt='2px'
                            size='md'
                            // color='white'
                            color={lockedGeneration && lockedGeneration.id === generation.id ? "white" : "whiteAlpha.300"}
                            variant='ghost'
                            _hover={{color: 'white'}}
                            _active={{}}
                            onClick={() => {
                                if (lockedGeneration) {
                                    setLockedGeneration(null)
                                } else {
                                    setLockedGeneration(generation)
                                }
                            }}
                        >
                            <IoDocumentLockOutline size='25'/>
                        </IconButton>
                    </Tooltip>
                }

                <Tooltip label='Download'>
                    <IconButton
                        aria-label='download'
                        size='md'
                        color='white'
                        variant='ghost'
                        _hover={{color: 'white'}}
                        _active={{}}
                        onClick={onDownload}
                    >
                        <IoDownloadOutline size='25'/>
                    </IconButton>
                </Tooltip>
            </Stack>
        </Box>
)
}
