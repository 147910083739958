import {
    VStack,
    HStack,
    Box,
    Text,
    Icon,
    Square,
    Stack
} from '@chakra-ui/react'

import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {ProductImage} from "./product-image";
import {RemoveButton} from "@krfts/app-shared-components";
import {IoAdd} from "react-icons/io5";

export const SourceImages = ({sourceImages, onImageRemoved, onFileDrop}) => {
    return (
        <VStack spacing={6}>
            <Text w={{base:'100%', md:'80%'}} fontSize={{base:'xs', md:'sm'}} align='center'>
                You can add additional 5 images to provide more details about the product. These can be from any angle and on any background.
            </Text>

            <Stack direction={{base:'column', md:'row'}} spacing={4}>
                {sourceImages.map((image, i) => (
                    <Box position='relative' key={i}>
                        <ProductImage image={image} onImageRemoved={onImageRemoved}/>
                        <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                            onImageRemoved(image)
                        }}/>
                    </Box>
                ))}
                {
                    sourceImages.length < 5 ?
                        <AssetDropzone onFileDrop={onFileDrop} assetType='data' boxSize={{base:'150px', md:'100px'}}>
                            <Square size="10">
                                <Icon as={IoAdd} boxSize="10" color="black"/>
                            </Square>
                        </AssetDropzone>
                        : null
                }
            </Stack>

        </VStack>
    )
}