import {Spinner, Center, Text, VStack} from '@chakra-ui/react'

export const LoadingIndicator = ({color, message}) => {
    return (
        <Center w='100%' h='100%' boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'>
            <VStack spacing={6}>
                <Text fontSize='xs' color='white'>{message ? message : ""}</Text>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    // emptyColor='gray.800'
                    color={color ? color : 'white.500'}
                    size='xl'
                />
            </VStack>
        </Center>
    )
}