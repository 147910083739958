import dayjs from 'dayjs'

const starter = ['monthly_starter', 'yearly_starter']
const standard = ['monthly_standard', 'yearly_standard']
const premium = ['monthly_premium', 'yearly_premium']

const starterCredits = (period) => {
    if (period === 'monthly') return 125
    if (period === 'yearly') return 1500
}
const standardCredits = (period) => {
    if (period === 'monthly') return 275
    if (period === 'yearly') return 3300
}
const premiumCredits = (period) => {
    if (period === 'monthly') return 650
    if (period === 'yearly') return 7800
}

const daysLeftUntilRenewal = (endDate) => {
    const now = Date.now();

    endDate = new Date(endDate._seconds * 1000 + endDate._nanoseconds / 1000000)

    const timeDif = endDate.getTime() - now;

    return Math.round(timeDif / (1000 * 3600 * 24));
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000)
    return dayjs(date).format('MMM D, YYYY')
}

export const subDescription = (plan) => {
    if (plan === 'free') return {title: 'Free', description: 'Our always free plan'}
    if (plan === 'starter') return {title: 'Starter', description: 'Our starter plan'};
    if (plan === 'standard') return {title: 'Standard', description: 'Best for medium usage'}
    if (plan === 'premium') return {title: 'Premium', description: 'For power users'}
}

export const getCreditsCopy = (subscription) => {
    if (subscription.current_plan === 'free') return 'Select a plan to get monthly credits'
    else return `${subscription.plan_period} credits will reset to ${starterCredits(subscription.plan_period)} in ${daysLeftUntilRenewal(subscription.plan_enddate)} days on ${formatDate(subscription.plan_enddate)}`
}