import {
    AspectRatio,
    Box,
    Image,
    Icon,
    Tooltip,
} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {calculateAspectRatio, createDownloadURL} from "@krfts/core-utils";
import {useProductsStore} from "../../../../state/products-store";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {IoLayersOutline} from "react-icons/io5";

export const ProductGenerationsGridEntry = ({generation}) => {

    const [imageURL, setImageURL] = useState(null)

    const setShowGenerationsDisplayModal = useProductsStore((state) => state.setShowGenerationsDisplayModal);
    const setSelectedGeneration = useProductsStore((state) => state.setSelectedGeneration);
    const bundleIterations = useProductsStore((state) => state.bundleIterations);

    useEffect(() => {
        const getImageURL = async () => {
            setImageURL(await createDownloadURL(generation.image.file))
        }


        if (generation.image) getImageURL()
    }, [])

    return (
        <AspectRatio ratio={generation ? calculateAspectRatio(generation.format) : 1} w='100%' bg='gray.200'>
            <Box cursor='pointer'>
                {!imageURL ?
                    <LoadingIndicator/>
                    :
                    <Box position='relative'>
                        <Image
                            src={imageURL}
                            onClick={
                                () => {
                                    setSelectedGeneration(generation)
                                    setShowGenerationsDisplayModal(true)
                                }
                            }
                        />
                        {generation.child_generations && generation.child_generations.length > 0 && bundleIterations &&
                            <Icon as={IoLayersOutline} position='absolute' bottom={2} right={2} color='white'
                                  fontSize='xl'/>
                        }
                    </Box>
                }
            </Box>
        </AspectRatio>
    )
}