import {useRef, useEffect, useState} from "react";
import {Canvas} from "@react-three/fiber";
import {CameraControls, Stage} from "@react-three/drei";
import {useProduct3DViewStore} from "../../../../state";
import {Product3dScene} from "./product-3d-scene";



export const Product3DView = ({glbURL}) => {

    const [sceneMesh, setSceneMesh] = useState(null)

    return (
        <Canvas w='100%' h='100%' style={{background: "black"}} gl={{preserveDrawingBuffer: true}} linear flat
                resize={{scroll: false}}
        >
            <color attach="background" args={["black"]}/>
            <Product3dScene url={glbURL}
                            setSceneMesh={setSceneMesh}
                            // resetCamera={resetCamera}
            />
        </Canvas>
    );
};

