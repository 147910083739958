import {Stack, Spacer, useRadioGroup, HStack, Box, Text, chakra} from '@chakra-ui/react'
import {PlanCard} from "../plan-card";
import {CoreModal} from "@krfts/core-modal";
import {MonthlyPlans} from "./monthly-plans";
import {useState} from "react";
import {PlansToggle} from "./plans-toggle";
import {AnnualPlans} from "./annual-plans";

export const PlansModal = ({isOpen, setShouldShowModal, user, selectedPlan}) => {

    const [showMonthlyPlans, setShowMonthlyPlans] = useState(true);

    const planCadences = ['Monthly', 'Yearly']
    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'plan-cadence',
        defaultValue: 'Monthly',
        onChange: () => {
            setShowMonthlyPlans(!showMonthlyPlans)
        },
    })

    const group = getRootProps()

    return (
        <CoreModal
            isOpen={isOpen}
            onCloseClicked={() => {
                setShouldShowModal(false)
            }}
            size='5xl'
            title='Choose your plan'
        >
            <Stack direction='column' align='center' w='100%' spacing={8}>

                <Stack direction={{base: 'column', md: 'row'}} spacing={2} w='100%' align='center' justify='center'>
                    <Spacer/>
                    <Box position='relative'>
                        <HStack {...group}>
                            {planCadences.map((value) => {
                                const radio = getRadioProps({value})
                                return (
                                    <PlansToggle key={value} {...radio}>
                                        {value}
                                    </PlansToggle>
                                )
                            })}
                        </HStack>
                        <Box
                            position='absolute'
                            top={-4}
                            right='-20px'
                            bg='black'
                            border={showMonthlyPlans ? '1px' : '2px'}
                            borderColor={showMonthlyPlans ? 'gray.600' : 'white'}
                            p={1}
                        >
                            <Text fontSize='xs' color={showMonthlyPlans ? 'gray.600' : 'white'}>-20%</Text>
                        </Box>
                    </Box>
                </Stack>

                <Stack direction='column' align='center' w='100%' spacing={4}>

                    <Stack direction={{base: 'column', md: 'row'}} spacing={2} w='100%'>
                        <PlanCard
                            title='Free'
                            price='$0'
                            features={[
                                "25 credits (one time)",
                                "1 product",
                                "watermark on images",
                                "watermark on videos",
                            ]}
                            user={user}
                            active={!selectedPlan}
                        />
                        <Spacer/>

                        {showMonthlyPlans ?
                            <MonthlyPlans user={user} selectedPlan={selectedPlan}/>
                            :
                            <AnnualPlans user={user} selectedPlan={selectedPlan}/>
                        }
                    </Stack>

                    <Box
                        w='100%'
                        borderWidth="2px"
                        borderRadius="none"
                        px={{base: '6', md: '8'}}
                        py="8"
                        bg='black'
                        color='white'
                    >
                        <Stack spacing={{base: '4', md: '4'}} textAlign="center">
                            <Stack align="center" spacing={4}>
                                <Text textStyle={{base: 'md', md: 'lg'}} fontWeight="semibold">
                                    Bespoke plans
                                </Text>
                            </Stack>
                            <Text fontSize={{base:'xs' ,md:'sm'}}>
                                Unlimited credits? Help with creating products and optimizing the AI model? Other custom requirements?
                            </Text>
                            <Text><chakra.span textDecor='underline'>Contact us</chakra.span> for a bespoke quote.</Text>
                        </Stack>

                    </Box>

                </Stack>

            </Stack>

        </CoreModal>

    )
}