import {Progress, Center, Text, VStack} from '@chakra-ui/react'
import {useEffect, useState} from "react";

export const LoadingTimedProgress = ({color, message, runTime}) => {

    const [progress, setProgress] = useState(0)

    const createTimer = (duration, onProgress, onComplete)=> {
        const startTime = Date.now(); // Record the start time
        const interval = 1000; // Frequency of progress updates (in milliseconds)

        const timer = setInterval(() => {
            const elapsedTime = Date.now() - startTime; // Calculate elapsed time
            const progress = Math.min((elapsedTime / duration) * 100, 100); // Calculate progress (clamped to 100)

            onProgress(progress); // Call the callback with the progress value

            if (elapsedTime >= duration) {
                clearInterval(timer); // Stop the timer when the duration is reached
                onComplete && onComplete(); // Call the completion callback, if provided
            }
        }, interval);
    }

    useEffect(() => {
        createTimer(runTime, (progress) => {
            // console.log(progress)
            setProgress(Math.floor(progress))
        })
    }, []);

    return (
        <Center w='100%' h='100%' boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'>
            <VStack spacing={6} w='80%'>
                <Text fontSize='xs' color='white'>{message ? message : ""}</Text>
                <Progress colorScheme='blackAlpha' value={progress} w='100%'/>
            </VStack>
        </Center>
    )
}