import {
    Stack,
    Text,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Center,
    Button,
    chakra, Tooltip,
} from '@chakra-ui/react'
import {StatusBadge} from "./status-badge"
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {regenerate3DModel} from "@krfts/app-data";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuthStore} from "@krfts/app-state";
import {costs} from "@krfts/app-common";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";

export const ProductState = ({product}) => {

    const [modelVideoURL, setModelVideoURL] = useState(null)
    const [retrainLoading, setRetrainLoading] = useState(false)

    const queryClient = useQueryClient()

    useEffect(() => {
        const getModelVideoURL = async () => {
            const url = await createDownloadURL(product.model_3D.video_file)
            // console.log("generated url:", url)
            setModelVideoURL(url)
        }

        if (product.model_3D && product.model_3D.video_file) {
            getModelVideoURL()
        }

    }, [product])

    /// Check if enough credits -- this needs to be put somewhere in common
    const currentUser = useAuthStore((state) => state.currentUser)
    const [hasEnoughCredits, setHasEnoughCredits] = useState(false)
    const {data} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })
    useEffect(() => {
        if (data) {
            const totalCredits = data.credits.purchased_credits + data.credits.plan_credits
            setHasEnoughCredits(totalCredits >= costs.lora)
        }
    }, [data]);
    /////////////////////////////

    return (
        <Accordion w={{base: '100%', md: '50%'}} allowToggle>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'>
                            <Stack direction={{base: 'row', md: 'row'}} spacing={{base: '4', md: '4'}}>
                                <Text fontSize='sm'>Product status:</Text>
                                <Center>
                                    <StatusBadge modelStatus={product.model_3D_generation.status}
                                                 loraStatus={product.lora_training.status}/>
                                </Center>
                            </Stack>
                        </Box>
                        <AccordionIcon/>
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Stack direction='row' spacing={10} align='left'>
                        <Box w='60%'>
                            <Stack direction={{base: 'column', md: 'column'}} spacing={{base: '2', md: '2'}}>
                                <Text fontSize='xs'>
                                    To generate images, we create a custom AI model of your product. This is a 2-step
                                    process where the AI learns both the shape and the materials of your product.
                                </Text>
                                {product.model_3D_generation.status === "DONE" ?
                                    <Text fontSize='xs'>
                                        The shape is now generated. If it isn't quite right at first go, the
                                        AI may need further training. Inspect the video and click 'Retrain' if needed
                                        <chakra.i> (costs 1 credit).</chakra.i>
                                    </Text> : null}

                                <Center mt={4}>
                                    {product.model_3D_generation.status === "DONE" ?
                                        <Tooltip
                                            label={hasEnoughCredits ?
                                                ''
                                                :
                                                "You don't have enough credits to retrain the shape. Please purchase more credits."
                                            }
                                            placement='top'
                                        >
                                            <Button
                                                size='sm'
                                                bg='yellow.300'
                                                _hover={{bg: 'yellow.100'}}
                                                w='50%'
                                                loadingText='Starting retraining'
                                                isLoading={retrainLoading}
                                                isDisabled={!hasEnoughCredits}
                                                onClick={() => {
                                                    setRetrainLoading(true)
                                                    setModelVideoURL(null)
                                                    regenerate3DModel(product.id)
                                                    setTimeout(() => {
                                                        queryClient.invalidateQueries({queryKey: ['product', product.id],})
                                                        setRetrainLoading(false)
                                                    }, 8000);
                                                }}
                                            >
                                                Retrain
                                            </Button>
                                        </Tooltip>
                                        : null}
                                </Center>
                            </Stack>
                        </Box>

                        {modelVideoURL ? <Box w='175px' h='175px'>
                            <Box
                                top={0}
                                w='100%'
                                h='100%'
                                as='video'
                                muted
                                autoPlay
                                controls
                                playsInline
                                src={modelVideoURL}
                                bg='black'
                                loop
                            />
                        </Box> : null}
                    </Stack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>


    )
}