export const priceIds = {
    starter: {
        monthly: "price_1QhvSEDA2SR6G3IsoYuLeGQx",
        yearly: "price_1QkoejDA2SR6G3IsRLMWd8fa"
    },
    standard: {
        monthly: "price_1QhvTcDA2SR6G3Is00Gm4loP",
        yearly: "price_1QkoezDA2SR6G3IsLvLdi4WF"
    },
    pro: {
        monthly: "price_1QhvUWDA2SR6G3IsxukoletT",
        yearly: "price_1QkofEDA2SR6G3IsUE95bgOx"
    },
    credits: {
        regular: "price_1QkrXnDA2SR6G3IsUa3bj9QO",
        discounted: "price_1QkrYrDA2SR6G3IsED4nSP1W"
    }
}