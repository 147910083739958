import {
    Stack, Button, HStack, Box, Text, Heading,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark, VStack, GridItem, Icon, Grid,
} from '@chakra-ui/react'
import {CoreModal} from "@krfts/core-modal";
import {useState} from "react";
import {createStripeCreditsCheckoutSession} from "@krfts/app-auth-modal/data/auth-api";
import {priceIds} from "@krfts/app-common/stripe-config";
import {FiCheck} from "react-icons/fi";

export const CreditsModal = ({isOpen, setShouldShowModal, user, selectedPlan}) => {

    const [loading, setLoading] = useState(false)
    const [credits, setCredits] = useState(25)

    return (
        <CoreModal
            isOpen={isOpen}
            onCloseClicked={() => {
                setShouldShowModal(false)
            }}
            size='lg'
            title='Buy extra credits'
        >
            <Stack direction='column' align='left' w='100%' spacing={8}
                   borderWidth="2px"
                   borderRadius="none"
                   px={{base: '6', md: '8'}}
                   py="8"
                   bg='black'
                   color='white'
            >
                <Text fontSize={{base: 'md', md: 'xl'}} fontWeight="semibold">Choose the amount of credits:</Text>

                <Slider aria-label='slider-ex-1' defaultValue={25} min={25} max={1000} step={25}
                        onChange={(value) => setCredits(value)}>
                    <SliderTrack>
                        <SliderFilledTrack bg='yellow.300'/>
                    </SliderTrack>
                    <SliderThumb boxSize={6}/>
                </Slider>

                <VStack spacing={1} align='center'>
                    <Text textStyle={{base: 'md', md: 'lg'}} fontWeight="semibold">{credits} credits</Text>
                    <Heading size={{base: 'md', md: 'lg'}}>${credits / 5}</Heading>
                </VStack>

                <Button
                    alignSelf='center'
                    w='60%'
                    bg='yellow.300'
                    _hover={{ bg: 'yellow.100' }}
                    isLoading={loading}
                    onClick={async () => {
                        setLoading(true)
                        const result = await createStripeCreditsCheckoutSession(user, priceIds.credits.regular, credits)
                        setLoading(false)
                        window.location.href = result.data.url
                    }}
                >
                    Buy Credits
                </Button>

            </Stack>

        </CoreModal>

    )
}