import {CoreModal} from "@krfts/core-modal";
import {privacyPolicy} from "./privacy-policy";
import {VStack, Box, chakra, Heading, Text, UnorderedList, ListItem} from "@chakra-ui/react";
import React from "react";

export const PrivacyPolicyModal = ({isOpen, onClose}) => {

    return (
        <CoreModal
            isOpen={isOpen}
            onCloseClicked={() => {
                onClose(true)
            }}
            size='4xl'
            title='Privacy Policy'
            // hideHeader
        >
            <Box p={4} bg='black' color='white'>
                <Heading as="h1" size="xl" mb={4}>
                    Privacy Policy for {privacyPolicy.company}
                </Heading>
                <Text fontSize="lg" mb={2}>
                    Effective Date: {privacyPolicy.effectiveDate}
                </Text>
                <Text mb={4}>{privacyPolicy.overview}</Text>
                {privacyPolicy.sections.map((section, index) => (
                    <Box key={index} mb={6}>
                        <Heading as="h2" size="lg" mb={2}>
                            {section.title}
                        </Heading>
                        {section.subsections
                            ? section.subsections.map((subsection, subIndex) => (
                                <Box key={subIndex} mb={4}>
                                    <Heading as="h3" size="md" mb={2}>
                                        {subsection.title}
                                    </Heading>
                                    <UnorderedList>
                                        {subsection.content.map((item, itemIndex) => (
                                            <ListItem key={itemIndex}>{item}</ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            ))
                            : section.content && (
                            <UnorderedList>
                                {section.content.map((item, itemIndex) => (
                                    <ListItem key={itemIndex}>{item}</ListItem>
                                ))}
                            </UnorderedList>
                        )}
                    </Box>
                ))}
            </Box>
        </CoreModal>
    )
}