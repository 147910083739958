import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerCloseButton,
    DrawerOverlay,
    useDisclosure,
    Text,
    IconButton,
    Icon,
    Center,
    HStack,
} from '@chakra-ui/react'
import {GenerationControls} from "./ui/generation-controls";
import {GenerationsList} from "./ui/generations-list";
import {useQuery} from "@tanstack/react-query";
import {getProducts} from "@krfts/app-data";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {useEffect} from "react";
import {useStudioStore} from "./state";
import {isMobile} from "react-device-detect";
import {IoOptionsOutline, IoDocumentLockOutline} from "react-icons/io5";

export const StudioContent = () => {

    const setSelectedProduct = useStudioStore((state) => state.setSelectedProduct);
    const selectedProduct = useStudioStore((state) => state.selectedProduct);
    const lockedGeneration = useStudioStore((state) => state.lockedGeneration);
    const setShouldDisplayLockedGeneration = useStudioStore((state) => state.setShouldDisplayLockedGeneration);
    const {isPending, data} = useQuery({queryKey: ['products'], queryFn: getProducts})

    useEffect(() => {
        if (!isPending) {
            let _selectedProduct = null
            if (!lockedGeneration) {
                if (selectedProduct) _selectedProduct = selectedProduct
                else _selectedProduct = data[0]
            } else {
                _selectedProduct = data.find(product => product.id === lockedGeneration.product_id)
            }
            setSelectedProduct(_selectedProduct)
        }
    }, [isPending, lockedGeneration]);

    const {isOpen, onOpen, onClose} = useDisclosure()

    const renderContent = () => {
        if (isPending) {
            return (<LoadingIndicator/>)
        } else {
            return (
                <>
                    {!isMobile ?
                        <Box w="400px" bg="gray.900" color="white" fontSize="sm" overflow='auto'>
                            <GenerationControls/>
                        </Box>
                        :
                        <IconButton
                            size='lg'
                            variant='ghost'
                            onClick={onOpen}
                            position='absolute'
                            aria-label='Open settings'
                            icon={<IoOptionsOutline size={28}/>}
                        />
                    }


                    <Box bg={lockedGeneration ? 'blackAlpha.700' : 'white'} flex="1" p="6" overflow='auto'
                         position='relative'>
                        <GenerationsList/>

                        {lockedGeneration ? <Box
                            bg='blackAlpha.500'
                            rounded='md'
                            w='50%'
                            h='40px'
                            position='absolute'
                            left='50%'
                            top={2}
                            transform='translate(-50%);'
                            cursor='pointer'
                            onClick={() => setShouldDisplayLockedGeneration(true)}
                        >
                            <Center h='100%'>
                                <HStack>
                                    <Icon as={IoDocumentLockOutline} boxSize='25px' color='white'/>
                                    <Text color='white' fontSize='lg'>Locked for iteration</Text>
                                </HStack>
                            </Center>
                        </Box> : null}

                    </Box>

                    <Drawer
                        isOpen={isOpen}
                        onClose={onClose}
                        placement='left'
                        size='lg'
                    >
                        <DrawerOverlay/>
                        <DrawerContent bg="gray.900" mt='56px' pb={40}>
                            <DrawerCloseButton color="white"/>


                            <DrawerBody color="white"
                                        fontSize="sm">
                                <GenerationControls mobileDrawerCallback={() => onClose()}/>
                            </DrawerBody>

                        </DrawerContent>
                    </Drawer>

                </>
            )
        }
    }

    return (
        renderContent()
    )
}