import {Stack} from '@chakra-ui/react'
import {PlanCard} from "../plan-card";
import {priceIds} from "@krfts/app-common/stripe-config";

export const AnnualPlans = ({user, selectedPlan}) => {
    return (
        <Stack direction={{base:'column', md:'row'}} spacing={4} justifyContent='space-between'>
            <PlanCard
                title='Starter'
                price='$240'
                priceId={priceIds.starter.yearly}
                active={selectedPlan === priceIds.starter.yearly}
                noSub={!selectedPlan}
                features={[
                    "1500 yearly credits",
                    "Unlimited products",
                    "Removed watermarks"
                ]}
                user={user}
            />
            <PlanCard
                title='Standard'
                price='$480'
                priceId={priceIds.standard.yearly}
                active={selectedPlan === priceIds.standard.yearly}
                noSub={!selectedPlan}
                features={[
                    "3300 yearly credits",
                    "Unlimited products",
                    "Removed watermarks"
                ]}
                user={user}
            />
            <PlanCard
                title='Pro'
                price='$960'
                priceId={priceIds.pro.yearly}
                active={selectedPlan === priceIds.pro.yearly}
                noSub={!selectedPlan}
                features={[
                    "7800 yearly credits",
                    "Unlimited products",
                    "Removed watermarks",
                    "Support contact",
                    "Discounted add'l credits"
                ]}
                user={user}
            />
        </Stack>
    )
}