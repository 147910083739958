import {Box, Spinner, Center, Button, VStack} from "@chakra-ui/react";
import bg_image from "./assets/bg_image.png";
import {TextBox} from "@krfts/core-text-box";
import {useAuthStore} from "@krfts/app-state";


export const UnauthCta = ({text, bg}) => {

    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal);
    const loading = useAuthStore((state) => state.loading);

    return (
        <>
            <Box w='100%' h='100%' bgImage={!loading ? bg_image : ""} bg={loading ? 'black' : null} bgSize='cover'
                 boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'>
                <Center w='100%' h='100%'>

                    {loading
                        ?
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='white.500'
                            size='xl'
                        />
                        :
                        <VStack w='100%' mt={8} px={20} spacing={12}>
                            <TextBox
                                text='Please sign in to continue'
                            />
                            <VStack spacing={2}>
                                <Button bg='yellow.300' _hover={{bg: 'yellow.100'}}
                                        onClick={() => setShowAuthModal(true)}>
                                    Sign In</Button>
                            </VStack>
                        </VStack>
                    }

                </Center>
            </Box>
        </>
    )
}