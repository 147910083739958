import {Box, VStack, Center, AspectRatio, Spinner, Stack} from "@chakra-ui/react";
import {useStudioStore} from "../../state";
import {GenerationEntry} from "@krfts/app-shared-components";
import {useQuery} from "@tanstack/react-query";
import {getGeneration} from "../../data/studio-api";
import {useEffect} from "react";

export const GenerationsList = () => {

    const currentSessionGenerations = useStudioStore((state) => state.currentSessionGenerations)
    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)

    const latestGenerationId = currentSessionGenerations.slice(-1)[0];

    const {data, isPending} = useQuery({
        queryKey: ['generation', latestGenerationId],
        queryFn: () => getGeneration(latestGenerationId),
    })

    const renderGenerations = () => {
        if (lockedGeneration) {
            return (
                <GenerationEntry
                    generationId={lockedGeneration.id}
                    studio
                    isLocked
                />
            )
        } else {
            return(
                currentSessionGenerations.map((generationId, i) => (
                    <GenerationEntry
                        key={i}
                        generationId={generationId}
                        studio
                        isFirst={i === 0}
                    />
                ))
            )
        }
    }

    if (isPending) return null

    return (
        <Box mt={8} mb={8} w='100%'>
            <Center>
                <Stack gap={6} w={{base:'90%', md:'80%'}} direction='column-reverse'>
                    {renderGenerations()}
                </Stack>
            </Center>
        </Box>
    );
}
