export const calculateAspectRatio = (imageFormat) => {
    if (imageFormat === "square" || imageFormat === "1024x1024 (1.0)") return 1
    if (imageFormat === "landscape" || imageFormat === "1280x768 (1.67)") return 1.67
    if (imageFormat === "portrait" || imageFormat === "768x1280 (0.6)") return 0.6
}

export const mapImageFormat = (imageFormat) => {
    if (imageFormat === "square" || imageFormat === "1024x1024 (1.0)") return "square"
    if (imageFormat === "landscape" || imageFormat === "1280x768 (1.67)") return "landscape"
    if (imageFormat === "portrait" || imageFormat === "768x1280 (0.6)") return "portrait"
}

export const centerCropImage = (image) => {
    const cropSize = Math.min(image.width, image.height); // The crop size (smallest dimension)
    const offsetX = (image.width - cropSize) / 2; // Center X axis for crop
    const offsetY = (image.height - cropSize) / 2; // Center Y axis for crop

    // Create a canvas to perform cropping
    const canvas = document.createElement("canvas");
    canvas.width = cropSize; // Width of the crop
    canvas.height = cropSize; // Height of the crop
    const ctx = canvas.getContext("2d");

    // Draw the cropped area on the canvas
    ctx.drawImage(
        image,
        offsetX, // Cropping starts at X offset
        offsetY, // Cropping starts at Y offset
        cropSize, // Width of the crop area
        cropSize, // Height of the crop area
        0, // Place it in Canvas X=0
        0, // Place it in Canvas Y=0
        cropSize, // Desired output width
        cropSize  // Desired output height
    );

    return canvas; // Return the cropped canvas
}