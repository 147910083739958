import {Container, Stack, Tab, TabIndicator, TabList, Tabs, TabPanels, TabPanel} from '@chakra-ui/react'

export const SectionTabs = ({sections, activeTab}) => {

    console.log(activeTab)

    return (
        <Container py={{base: '12', md: '16'}} maxW='container.lg'>
            <Stack spacing="16">
                <Tabs size='lg' colorScheme='dark' defaultIndex={activeTab === 'dashboard' ? 1 : 0}>
                    <TabList>
                        {
                            sections.map((section, i) => (
                                <Tab _active={{}} key={i}>{section.title}</Tab>
                            ))
                        }
                    </TabList>
                    <TabIndicator mt='-3px' height='4px' bg='yellow.300' />

                    <TabPanels>
                        {
                            sections.map((section, i) => (
                                <TabPanel key={i}>{section.component}</TabPanel>
                            ))
                        }
                    </TabPanels>
                </Tabs>
            </Stack>
        </Container>
    )
}