import { CopyrightSection } from "./ui/copyright-section";
import { SocialMediaSection } from "./ui/socialmedia-section";
import { SubscribeSection } from "./ui/subscribe-section";
import { LinkSection } from "./ui/link-section";

import { Logo } from "@krfts/app-logo";
import { Box, Stack, StackDivider, Image } from "@chakra-ui/react";

export const Footer = () => {
    return (
        <Box
            as="footer"
            bg="#101214"
            role="contentinfo"
            boxShadow="dark-lg"
            mx="auto"
            pt={8}
            pb={4}
            px={{
                base: "8",
                lg: "40",
            }}>
            <Stack spacing="6" divider={<StackDivider />}>
                <Stack
                    direction={{
                        base: "column",
                        lg: "row",
                    }}
                    spacing={{
                        base: "6",
                        lg: "28",
                    }}
                    justifyContent="space-between"
                >
                    <Box maxW={{base:40, md:28}} mr={10}>
                        <Logo/>
                    </Box>
                    <Stack
                        px={4}
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                        spacing={{
                            base: "10",
                            md: "20",
                        }}>
                        <LinkSection
                            spacing={{
                                base: "10",
                                md: "20",
                            }}
                            flex="1"
                        />
                        <SubscribeSection
                            width={{
                                base: "full",
                                md: "sm",
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack
                    direction={{
                        base: "column-reverse",
                        md: "row",
                    }}
                    justifyContent="space-between"
                    alignItems="center">
                    <CopyrightSection />
                    <SocialMediaSection />
                </Stack>
            </Stack>
        </Box>
    );
};