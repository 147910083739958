import {Layout} from '@krfts/core-layout'
import {NavBar} from '@krfts/app-navbar'
import {Footer} from '@krfts/app-footer'
import {AuthModal} from "@krfts/app-auth-modal";
import {StudioContent} from "@krfts/app-studio-content";
import {UnauthCta} from "@krfts/core-unauth-cta";
import {useAuthStore} from "@krfts/app-state";

export const Studio = () => {
    const user = useAuthStore((state) => state.currentUser);

    return (
        <Layout
            topBar={<NavBar/>}
            page={user ? <StudioContent/> : <UnauthCta/>}
            footer={!user ? <Footer/> : null}
            authModal={<AuthModal/>}
        />
    )
}