import {AspectRatio} from "@chakra-ui/react";
import {CoreModal} from "@krfts/core-modal";
import {GenerationEntry} from "@krfts/app-shared-components";
import {useProductsStore} from "../../state/products-store";
import {calculateAspectRatio} from "@krfts/core-utils";

export const GenerationDisplayModal = () => {

    const showGenerationsDisplayModal = useProductsStore((state) => state.showGenerationsDisplayModal);
    const setShowGenerationsDisplayModal = useProductsStore((state) => state.setShowGenerationsDisplayModal);

    const generation = useProductsStore((state) => state.selectedGeneration);

    return (
        <CoreModal
            isOpen={showGenerationsDisplayModal}
            onCloseClicked={() => {
                setShowGenerationsDisplayModal(false)
            }}
            size='2xl'
            title="View generation"
        >
            {generation ?
                <AspectRatio ratio={generation ? calculateAspectRatio(generation.format) : 1} w='100%' bg='gray.200'>
                <GenerationEntry generationId={generation.id} studio={false}/>
            </AspectRatio> : null
            }
        </CoreModal>
    )
}