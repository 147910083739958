import {AspectRatio, Stack, Center, Box} from "@chakra-ui/react";
import {ModelViewer} from "@krfts/core-model-viewer";
import {ModelViewerStudioScene} from "../model-viewer-studio-scene";
import {calculateAspectRatio} from "@krfts/core-utils";
import {useStudioStore} from "../../../state";
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {TransformControls} from "./transform-controls";
import {Product3DView} from "./product-3d-view";

export const ProductPositioner = () => {

    const [modelURL, setModelURL] = useState(null)

    const imageFormat = useStudioStore((state) => state.imageFormat)
    const selectedProduct = useStudioStore((state) => state.selectedProduct)
    const shouldResetTransform = useStudioStore((state) => state.shouldResetTransform)

    useEffect(() => {
        const getModelURL = async () => {
            setModelURL(await createDownloadURL(selectedProduct.model_3D.file))
        }
        if (selectedProduct) getModelURL()
    }, [selectedProduct])

    return (
        <Stack direction='column' spacing={0} w='100%' h='100%'>
            <AspectRatio ratio={calculateAspectRatio(imageFormat)} w='100%'>
                <Box w='100%' h='100%'>
                    {modelURL ? <Product3DView glbURL={modelURL}/> : null}
                </Box>
                {/*<ModelViewer enableZoom={false} enableRotate={true} reset={shouldResetTransform}>*/}
                {/*    {modelURL ? <ModelViewerStudioScene modelURL={modelURL}/> : null}*/}
                {/*</ModelViewer>*/}
            </AspectRatio>
            <Center>
                <TransformControls/>
            </Center>
        </Stack>
    )
}
