import {
    HStack,
    Text,
    Icon,
} from '@chakra-ui/react'
import {IoFilmOutline, IoImageOutline} from "react-icons/io5";

export const GenerationsCount = ({product}) => {

    return (
        <HStack spacing={4}>
            <HStack spacing={1}>
                <Icon as={IoImageOutline} color='white'/>
                <Text
                    color='white'
                    fontSize="xs"
                    textTransform="uppercase"
                >
                    {product.images_generated}
                </Text>
            </HStack>

            <HStack spacing={1}>
                <Icon as={IoFilmOutline} color='white'/>
                <Text
                    color='white'
                    fontSize="xs"
                    textTransform="uppercase"
                >
                    {product.videos_generated}
                </Text>
            </HStack>
        </HStack>
    )
}