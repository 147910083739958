import {Layout} from '@krfts/core-layout'
import {NavBar} from '@krfts/app-navbar'
import {Footer} from '@krfts/app-footer'
import {AuthModal} from "@krfts/app-auth-modal";
import {UnauthCta} from "@krfts/core-unauth-cta";
import {useAuthStore} from "@krfts/app-state";
import {LandingContent} from "@krfts/app-landing-content";

export const Landing = () => {
    const user = useAuthStore((state) => state.currentUser);

    return (
        <Layout
            topBar={null}
            page={<LandingContent/>}
            footer={null}
            authModal={<AuthModal/>}
        />
    )
}