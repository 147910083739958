import {CoreModal} from "@krfts/core-modal";
import {
    Center,
    Container,
    Stack,
    Button,
    Tooltip,
} from "@chakra-ui/react";
import {useNavigate} from "react-router";
import {useState, useRef, useEffect} from "react";
import {Metadata} from "./metadata";
import {SourceImages} from "./source-images";
import {createProduct} from "../../../data"
import {ModelImages} from "./model-images";
import Compressor from 'compressorjs';
import {centerCropImage} from "@krfts/core-utils";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuthStore} from "@krfts/app-state";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";
import {costs} from "@krfts/app-common";

export const NewProductModal = ({showModal, setShowModal}) => {

    const [isCreating, setIsCreating] = useState(false);

    const [productName, setProductName] = useState("");
    const [productImage, setProductImage] = useState(null);
    const [sourceImages, setSourceImages] = useState([]);
    const sourceImagesRef = useRef(new Map());

    const [modelImageLeft, setModelImageLeft] = useState(null);
    const [modelImageRight, setModelImageRight] = useState(null);
    const [modelImageFront, setModelImageFront] = useState(null);
    const [modelImageBack, setModelImageBack] = useState(null);
    const [modelImageTop, setModelImageTop] = useState(null);

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    /// Check if enough credits -- this needs to be put somewhere in common
    const currentUser = useAuthStore((state) => state.currentUser)
    const [hasEnoughCredits, setHasEnoughCredits] = useState(false)
    const {data} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })
    useEffect(() => {
        if (data) {
            const totalCredits = data.credits.purchased_credits + data.credits.plan_credits
            setHasEnoughCredits(totalCredits >= costs.lora)
        }
    }, [data]);
    /////////////////////////////

    const onFileDropped = (files, type) => {
        files.map((file) => {
            if (file.type.startsWith("image/")) {

                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    const img = new Image();
                    img.onload = function () {
                        // Perform center cropping
                        const croppedCanvas = centerCropImage(img);

                        // Convert cropped image back to a Blob
                        croppedCanvas.toBlob((blob) => {
                            const croppedFile = new File([blob], file.name, {type: file.type});

                            // Compress the cropped image
                            new Compressor(croppedFile, {
                                quality: 0.8,
                                width: 2048,
                                convertTypes: ['image/png', 'image/webp', 'image/avif'],
                                convertSize: 500000,

                                success(compressedFile) {
                                    console.log("compression complete");

                                    // Prepare the final file
                                    const finalReader = new FileReader();
                                    finalReader.addEventListener("load", function () {
                                        const newFile = {
                                            imageSrc: finalReader.result,
                                            file: compressedFile,
                                        };

                                        // Handle the file based on the type (same logic as before)
                                        if (type === "data") {
                                            if (!sourceImagesRef.current.has(file.name) && sourceImagesRef.current.size < 5) {
                                                sourceImagesRef.current.set(file.name, newFile);
                                            }
                                            setSourceImages(Array.from(sourceImagesRef.current.values()));
                                        } else if (type === "metadata") {
                                            console.log("setting productImage:", newFile);
                                            setProductImage(newFile);
                                        } else if (type === "Left View") {
                                            setModelImageLeft(newFile);
                                        } else if (type === "Right View") {
                                            setModelImageRight(newFile);
                                        } else if (type === "Front View") {
                                            setModelImageFront(newFile);
                                        } else if (type === "Back View") {
                                            setModelImageBack(newFile);
                                        } else if (type === "Top View") {
                                            setModelImageTop(newFile);
                                        }
                                    });

                                    if (compressedFile) finalReader.readAsDataURL(compressedFile);
                                },
                                error(err) {
                                    console.log(err.message);
                                },
                            });
                        });
                    };
                    img.src = reader.result;
                });

                if (file) reader.readAsDataURL(file);
            }
        });
    };

    const onSourceImageRemoved = (image) => {
        sourceImagesRef.current.delete(image.file.name);
        setSourceImages(Array.from(sourceImagesRef.current.values()));
    }

    const onGenerateClicked = async () => {
        setIsCreating(true)

        const modelImages = [
            modelImageLeft && modelImageLeft,
            modelImageRight && modelImageRight,
            modelImageFront && modelImageFront,
            modelImageBack && modelImageBack,
            modelImageTop && modelImageTop,
        ].filter(image => image)

        console.log("modelImages:", modelImages)

        const response = await createProduct(productName, productImage, sourceImages, modelImages)

        console.log("createProduct response:", response)

        // should probably navigate to the newly created product
        setProductName("")
        setProductImage(null)
        setSourceImages([])
        sourceImagesRef.current = new Map()
        setIsCreating(false)
        setShowModal(false)
        setModelImageLeft(null)
        setModelImageRight(null)
        setModelImageFront(null)
        setModelImageBack(null)
        setModelImageTop(null)

        queryClient.invalidateQueries({queryKey: ['products']})

        navigate("/products/" + response.result.id)
    }

    const canSubmit = () => {
        if (!hasEnoughCredits) return true
        else return !((modelImageLeft || modelImageRight || modelImageFront || modelImageBack || modelImageTop) && productImage && productName)
    }

    return (
        <CoreModal
            isOpen={showModal}
            onCloseClicked={() => {
                setShowModal(false)
            }}
            size='4xl'
            title='Create a New Product'
            buttonComponents={
                <Tooltip
                    label={hasEnoughCredits ?
                        ''
                        :
                        "You don't have enough credits to create a new product. Please purchase more credits."
                    }
                    placement='top'
                >
                    <Button
                        bg='yellow.300'
                        _hover={{bg: 'yellow.100'}}
                        mr={3}
                        disabled={canSubmit()}
                        isLoading={isCreating}
                        onClick={() => onGenerateClicked()}
                    >
                        Send to AI
                    </Button>
                </Tooltip>
            }
        >

            <Center w='100%' h='100%'>
                <Container maxW="4xl" py={{base: '12', md: '12'}} bg='black' p={8}
                           boxShadow="dark-lg" color='white'>
                    <Stack direction='column' spacing="8">
                        <Metadata
                            name={productName}
                            onNameChange={setProductName}
                            onFileDrop={onFileDropped}
                            productImage={productImage}
                            onProductImageRemoved={setProductImage}
                        />

                        <Center>
                            <ModelImages
                                modelImageLeft={modelImageLeft}
                                setImage={onFileDropped}
                                setModelImageLeft={setModelImageLeft}
                                modelImageRight={modelImageRight}
                                setModelImageRight={setModelImageRight}
                                modelImageFront={modelImageFront}
                                setModelImageFront={setModelImageFront}
                                modelImageBack={modelImageBack}
                                setModelImageBack={setModelImageBack}
                                modelImageTop={modelImageTop}
                                setModelImageTop={setModelImageTop}
                            />
                        </Center>

                        <SourceImages
                            sourceImages={sourceImages}
                            onImageRemoved={onSourceImageRemoved}
                            onFileDrop={onFileDropped}
                        />

                    </Stack>
                </Container>
            </Center>


        </CoreModal>
    )
}