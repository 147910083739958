import { Box, Flex, HStack, Img, useMenuButton } from '@chakra-ui/react'
import { HiSelector } from 'react-icons/hi'
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {useStudioStore} from "../../../state";

export const ProductSwitcherButton = () => {

    const [productImageURL, setProductImageURL] = useState(null)

    const selectedProduct = useStudioStore((state) => state.selectedProduct);

    useEffect(() => {
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(selectedProduct.product_image.file))
        }
        if (selectedProduct) getProductImageURL()
    }, [selectedProduct])

    const buttonProps = useMenuButton()
    return (
        <Flex
            as="button"
            {...buttonProps}
            w="100%"
            display="flex"
            alignItems="center"
            rounded="lg"
            bg="gray.700"
            px="3"
            py="2"
            fontSize="sm"
            userSelect="none"
            cursor="pointer"
            outline="0"
            transition="all 0.2s"
            _active={{ bg: 'gray.600' }}
            _focus={{ shadow: 'outline' }}
        >
            <HStack flex="1" spacing="3">
                <Img
                    w="8"
                    h="8"
                    rounded="md"
                    objectFit="cover"
                    src={productImageURL}
                />
                <Box textAlign="start">
                    <Box noOfLines={1} fontWeight="semibold">
                        {selectedProduct ? selectedProduct.name : ""}
                    </Box>
                    <Box fontSize="2xs" color="gray.400">
                        ID: {selectedProduct ? selectedProduct.id : ""}
                    </Box>
                </Box>
            </HStack>
            <Box fontSize="lg" color="gray.400">
                <HiSelector />
            </Box>
        </Flex>
    )
}