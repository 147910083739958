import {Center, VisuallyHidden, VStack, Input, AspectRatio} from '@chakra-ui/react'
import Dropzone from "react-dropzone";


export const AssetDropzone = (props) => {

    return (
        <AspectRatio
            ratio={props.ratio ? props.ratio : 1}
            boxSize={props.boxSize ? props.boxSize : null}
            bg={props.bg ? props.bg : 'white'}
            w={props.w ? props.w : null}
            rounded={props.rounded ? props.rounded : 'none'}
        >
            <Dropzone onDrop={(acceptedFiles) => props.onFileDrop(acceptedFiles, props.assetType)}>
                {({getRootProps, getInputProps}) => (
                    <Center p={props.p}>
                        <VStack spacing="3" {...getRootProps()}>
                            <VisuallyHidden>
                                <Input
                                    id='productPhoto'
                                    name='productPhoto'
                                    type="file"
                                    {...getInputProps()}
                                />
                            </VisuallyHidden>
                            {props.children}
                        </VStack>
                    </Center>
                )}
            </Dropzone>
        </AspectRatio>
    )
}