import {AspectRatio, Box} from '@chakra-ui/react'
import {GenerationEntryImage} from "./generation-entry-image";
import {GenerationEntryVideo} from "./generation-entry-video";
import {GenerationEntryToolbar} from "./generation-entry-toolbar";
import {useEffect, useState} from "react";
import {calculateAspectRatio, createDownloadURL} from "@krfts/core-utils";
import {queueVideoGeneration} from "@krfts/app-studio-content/data/studio-api";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getGeneration} from "@krfts/app-studio-content/data/studio-api";
import {saveAs} from 'file-saver';
import {useAuthStore} from "@krfts/app-state";
import {useStudioStore} from "@krfts/app-studio-content/state";

export const Generation = ({generationId, studio, isFirst}) => {

    const [videoPrompt, setVideoPrompt] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const [media, setMedia] = useState('image')

    const [isGeneratingImage, setIsGeneratingImage] = useState(false)
    const [imageGenerationError, setImageGenerationError] = useState(false)
    const [isGeneratingVideo, setIsGeneratingVideo] = useState(false)
    const [videoGenerationError, setVideoGenerationError] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const queryClient = useQueryClient()
    const currentUser = useAuthStore((state) => state.currentUser)

    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)
    const inpaintingImage = useStudioStore((state) => state.inpaintingImage);

    const {data, isPending} = useQuery({
        queryKey: ['generation', generationId],
        queryFn: () => getGeneration(generationId),
        refetchInterval: (data) => {
            if (
                data.state.data &&
                (
                    (data.state.data.video_generation_data &&
                        (
                            data.state.data.video_generation_data.state === 'WAITING' ||
                            data.state.data.video_generation_data.state === 'RUNNING'
                        )) ||
                    (data.state.data.image_generation_data &&
                        (
                            data.state.data.image_generation_data.state === 'WAITING' ||
                            data.state.data.image_generation_data.state === 'RUNNING'
                        ))
                )
            ) {
                return 3000
            } else {
                return false
            }
        }
    })

    useEffect(() => {
        if (!data) return
        const getImageURL = async () => {
            setImageURL(await createDownloadURL(data.image.file))
        }

        const getVideoURL = async () => {
            setVideoURL(await createDownloadURL(data.video.file))
        }

        if (data.image) {
            getImageURL()
        }
        if (data.video) {
            getVideoURL()
        }
    }, [data]);


    useEffect(() => {
        if (!data || isPending) return

        if (data.video_generation_data) {
            if (data.video_generation_data.state === 'WAITING' || data.video_generation_data.state === 'RUNNING' && !isGeneratingVideo) {
                setIsGeneratingVideo(true)
            }

            if (data.video_generation_data.state === 'DONE') {
                queryClient.invalidateQueries({queryKey: ['user', currentUser.uid]})
                setIsGeneratingVideo(false)
            }

            if (data.video_generation_data.state === 'ERROR') {
                setIsGeneratingVideo(false)
                setVideoGenerationError(true)
            }
        }

        if (data.image_generation_data) {
            if (data.image_generation_data.state === 'WAITING' || data.image_generation_data.state === 'RUNNING' && !isGeneratingImage) {
                if (!imageGenerationError) setImageGenerationError(false)
                setIsGeneratingImage(true)
            }
            if (data.image_generation_data.state === 'DONE') {
                if (!imageGenerationError) setImageGenerationError(false)
                queryClient.invalidateQueries({queryKey: ['user', currentUser.uid]})
                setIsGeneratingImage(false)
            }

            if (data.image_generation_data.state === 'ERROR') {
                setIsGeneratingImage(false)
                setImageGenerationError(true)
            }
        }
    }, [data]);

    const onGenerateVideoClicked = async () => {
        setIsSubmitting(true)
        setVideoGenerationError(false)
        const result = await queueVideoGeneration(data, videoPrompt, imageURL)
        setIsSubmitting(false)
        queryClient.invalidateQueries(['generation', data.id])
        setIsGeneratingVideo(true)
    }

    if (isPending) return null

    return (
        <AspectRatio ratio={data ? calculateAspectRatio(data.format) : 1} w='100%' bg='gray.200'>
            <Box position='relative' w='100%'>
                {media === "image" ?
                    <GenerationEntryImage
                        imageURL={imageURL}
                        isGenerating={isGeneratingImage}
                        studio={studio}
                        isFirst={isFirst}
                        error={imageGenerationError}
                        isInpainting={lockedGeneration && inpaintingImage && (generationId === lockedGeneration.id)}
                    />
                    :
                    <GenerationEntryVideo
                        imageURL={imageURL}
                        videoURL={videoURL}
                        videoPrompt={videoPrompt}
                        setVideoPrompt={setVideoPrompt}
                        isSubmitting={isSubmitting}
                        isGenerating={isGeneratingVideo}
                        error={videoGenerationError}
                        onGenerateVideoClicked={onGenerateVideoClicked}
                    />
                }
                {
                    isGeneratingImage || imageGenerationError || videoGenerationError ? null :
                        <GenerationEntryToolbar
                            studio={studio}
                            media={media}
                            onSetMedia={setMedia}
                            generation={data}
                            onDownload={() => {
                                if (media === 'image') {
                                    saveAs(imageURL, "krfts-generation.png");
                                } else {
                                    if (videoURL) saveAs(videoURL, "krfts-generation.mp4");
                                }
                            }}
                        />
                }
            </Box>
        </AspectRatio>
    )
}
