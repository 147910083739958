import {Box, Spinner, Center, Heading, HStack, Stack, SimpleGrid,} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import bg_image from './assets/bg_image.jpg'
import {getProducts} from "@krfts/app-data"
import {LoadingIndicator} from "@krfts/core-ui-library";
import {ProductCard} from "@krfts/app-products-content/ui/product-list/product-card";

import {useQuery} from '@tanstack/react-query'
import {TextBox} from "@krfts/core-text-box";

export const DashboardContent = () => {

    const {isPending, isError, data, error} =
        useQuery(
            {
                queryKey: ['latestProducts'],
                queryFn: getProducts
            })

    return (
        <>
            <Box
                w='100%'
                h='100%'
                bgImage={bg_image}
                bgSize='cover'
                overflow='auto'
                position='relative'
                boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'
            >
                <Center color='white' h='100%'>
                    <Stack direction='column' px={{base: 10, md: 40}}>
                        <Heading
                            size={{base: "md", md: "md"}}
                            bg="black"
                            p={2}
                            w='30%'
                        >
                            Latest Products:
                        </Heading>

                        <SimpleGrid columns={5} gap={2}>
                            {isPending ? <LoadingIndicator/> : data
                                .sort((a, b) => new Date(b.created_at._seconds) - new Date(a.created_at._seconds))
                                .slice(0, 5)
                                .map(product => <ProductCard p={2} hideInfo maxW='125px' fontSize='3xs' key={product.id} product={product}/>)}
                        </SimpleGrid>
                        <HStack>

                        </HStack>
                    </Stack>
                </Center>
            </Box>
        </>
    )
}