import {VStack, Text, IconButton} from '@chakra-ui/react'

export const FormatOption = ({format, name, onClick, children}) => {

    return (
        <VStack>
            <IconButton
                aria-label={name}
                size='lg'
                bg={format.toLowerCase() === name.toLowerCase() ? 'gray.700' : ''}
                color='white'
                variant='ghost'
                onClick={onClick}
                _hover={{bg: 'gray.600'}}
            >
                {children}
            </IconButton>
            <Text fontSize='xs'>{name}</Text>
        </VStack>
    )
}