export const privacyPolicy = {
    effectiveDate: "Jan 22 2025",
    company: "Imaginate Oy",
    headquarters: "Helsinki, Finland",
    overview: "Imaginate Oy (\"Imaginate,\" \"we,\" \"our,\" or \"us\") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and disclose your personal data through our services and ensures compliance with the General Data Protection Regulation (GDPR).",
    sections: [
        {
            title: "Information Collection",
            subsections: [
                {
                    title: "1. Information You Provide Directly:",
                    content: [
                        "**Identifiers and Contact Data:** Such as your name, email address, phone number, mailing address, billing address, and professional title.",
                        "**Demographic Data:** Including your city, state, country of residence, postal code, and age.",
                        "**Account Login and Profile Data:** Such as username, password, profile picture, preferences, and other details in your account profile.",
                        "**Communications Data:** Information from exchanges when you contact us through the service, email, or social media.",
                        "**Commercial and Transactional Data:** Details related to your purchases, such as order numbers and transaction history.",
                        "**User-Generated Content:** Photos, images, music, videos, comments, messages, and other content you submit."
                    ]
                },
                {
                    title: "2. Information Collected Automatically:",
                    content: [
                        "**Device Data:** Such as IP address, browser type, operating system, and device identifiers.",
                        "**Internet Network Activity Data:** Including browsing and search history, and interactions with our website or services.",
                        "**Cookies and Similar Technologies:** Data collected through cookies and similar tools to improve user experience and analyze website performance."
                    ]
                }
            ]
        },
        {
            title: "Use of Information",
            content: [
                "Provide, operate, and improve our services.",
                "Personalize your experience, including remembering your preferences and devices.",
                "Facilitate account creation and secure login.",
                "Communicate with you about our services, including updates, security alerts, and support messages.",
                "Analyze usage to improve our services and develop new products and features.",
                "Send direct marketing communications, where permitted by law, and personalize these messages based on your interests."
            ]
        }
    ]
};
