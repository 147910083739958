import axios from "axios";
import {API_BASE_URL, BETA_AUTH, BETA_REGISTER} from "@krfts/app-common";

export const authenticateBetaCode = async (betaCode) => {

    const data = {
        betaCode: betaCode,
    }

    try {
        const response = await axios.post(API_BASE_URL + BETA_AUTH, data, {

        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const registerForBeta = async (email, message) => {

    const data = {
        email: email,
        message: message,
    }

    try {
        const response = await axios.post(API_BASE_URL + BETA_REGISTER, data, {

        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}
