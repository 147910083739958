import {
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    VStack,
    Box,
    Divider,
} from '@chakra-ui/react'
import {GenerationsCount} from "@krfts/app-shared-components"
import {createDownloadURL} from "@krfts/core-utils"
import {useEffect, useState} from "react"
import {ProductGenerationsGrid} from "./product-generations-grid";
import {ProductState} from "./product-state";

export const ProductData = ({product}) => {

    const [productImageURL, setProductImageURL] = useState(null)
    const [modelURL, setModelURL] = useState(null)


    useEffect(() => {
        // const getModelURL = async () => {
        //     setModelURL(await createDownloadURL(product.model_3D.file))
        // }
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(product.product_image.file))
        }

        getProductImageURL()
        // getModelURL()

    }, [])

    return (

        <Stack direction='column' spacing={4} maxH='3xl' w='100%'>

            <Stack direction={{base:'column', md:'row'}} spacing={{base: '6', lg: '12'}} px={4} pb={4}>
                <HStack spacing={8} align="top" w='50%'>
                    <Image src={productImageURL} alt={product.name} boxSize={36}/>
                    <Stack spacing={{base: '2', md: '2'}}>
                        <Heading size="lg" fontWeight="medium">
                            {product.name}
                        </Heading>
                        <Text fontSize='xs' color='gray.600'>ID: {product.id}</Text>
                        <GenerationsCount product={product}/>
                    </Stack>
                </HStack>

                <ProductState product={product}/>

            </Stack>

            <Divider/>

            <Box h='100%' overflow='auto'
                 sx={{
                     '&::-webkit-scrollbar': {
                         width: '4px',
                     },
                     '&::-webkit-scrollbar-track': {
                         width: '6px',
                     },
                     '&::-webkit-scrollbar-thumb': {
                             background: "gray",
                             borderRadius: '24px',
                         }
            }}
            >

                    <ProductGenerationsGrid product={product}/>

            </Box>
        </Stack>
    )
}