import {
    Box,
    HStack,
    Img,
    MenuItem,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {useStudioStore} from "../../../state";

export const ProductSwitcherEntry = ({product}) => {

    const setSelectedProduct = useStudioStore((state) => state.setSelectedProduct);
    const setLockedGeneration = useStudioStore((state) => state.setLockedGeneration);
    const setInpaintingImage = useStudioStore((state) => state.setInpaintingImage)

    const [productImageURL, setProductImageURL] = useState(null)

    useEffect(() => {
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(product.product_image.file))
        }
        getProductImageURL()
    }, [])

    return (
        <Tooltip
            label={product.lora_training.status === 'DONE' && product.model_3D_generation.status === 'DONE' ? '' : 'The AI-model for this product is still being created'}
            placement="right"
        >
            <MenuItem rounded="md" onClick={() => {
                if (product.lora_training.status === 'DONE' && product.model_3D_generation.status === 'DONE') {
                    setLockedGeneration(null)
                    setInpaintingImage(false)
                    setSelectedProduct(product)
                }
            }} w='80%'>
                <HStack flex="1" spacing="3">
                    <Img
                        w="8"
                        h="8"
                        rounded="md"
                        objectFit="cover"
                        src={productImageURL}
                    />
                    <Box textAlign="start">
                        <Text noOfLines={1} fontWeight="semibold">
                            {product.name}
                        </Text>
                        <Text fontSize="2xs" color="gray.400" isTruncated>
                            ID: {product.id}
                        </Text>
                    </Box>
                </HStack>
            </MenuItem>
        </Tooltip>
    )
}