import {Layout} from '@krfts/core-layout'
import {NavBar} from '@krfts/app-navbar'
import {Footer} from '@krfts/app-footer'
import {AuthModal} from "@krfts/app-auth-modal";
import {ProductsContent} from "@krfts/app-products-content";
import {UnauthCta} from "@krfts/core-unauth-cta";
import {useAuthStore} from "@krfts/app-state";

export const Products = () => {
    const user = useAuthStore((state) => state.currentUser);

    return (
        <Layout
            topBar={<NavBar/>}
            page={user ? <ProductsContent/> : <UnauthCta/>}
            footer={<Footer/>}
            authModal={<AuthModal/>}
        />
    )
}