import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    StackDivider,
    Heading,
    VStack,
    Icon,
    Square, Text, HStack,
} from '@chakra-ui/react'
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {IoPencil} from "react-icons/io5";
import {getCreditsCopy, subDescription} from "../utils/dashboard-utils";
import {checkStripeCustomerSubscriptionStatus, getStripeBillingSessionUrl} from "@krfts/app-auth-modal/data/auth-api";


export const UserProfile = ({user}) => {

    return (

        <VStack spacing={14} w='100%' align='start'>
            <Heading size='md'>Your Profile</Heading>

            <Stack spacing="5" divider={<StackDivider/>} w='60%'>
                <FormControl id="name">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Name</FormLabel>
                        <Input maxW={{md: '3xl'}} defaultValue="" placeholder="Your name"/>
                    </Stack>
                </FormControl>
                <FormControl id="email">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Email</FormLabel>
                        <Input type="email" maxW={{md: '3xl'}} defaultValue={user.email}/>
                    </Stack>
                </FormControl>
                <FormControl id="picture">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Photo</FormLabel>
                        <Stack
                            spacing={{base: '3', md: '5'}}
                            direction={{base: 'column', sm: 'row'}}
                            width="full"
                            maxW={{md: '3xl'}}
                        >

                            <Box position='relative'>
                                <Avatar size="lg" name={user.email} position='absolute'/>

                                <AssetDropzone onFileDrop={() => console.log("Implement me")} bg='transparent'
                                               border={0} boxSize='65px'>
                                    <VStack spacing="1">
                                        <Square size="16" bg="bg.subtle" borderRadius="lg" bg='transparent'
                                                rounded='full' color="transparent"
                                                _hover={{color: "black", bg: "blackAlpha.400"}}>
                                            <Icon as={IoPencil} boxSize="6"/>
                                        </Square>
                                    </VStack>
                                </AssetDropzone>

                            </Box>

                        </Stack>
                    </Stack>
                </FormControl>
                <Flex direction="row-reverse">
                    <Button bg='yellow.300' _hover={{bg: 'yellow.100'}}>Save</Button>
                </Flex>
            </Stack>

        </VStack>



        // <Container py={{base: '4', md: '8'}}>
        //     <Stack spacing="5">
        //         <Stack spacing="4" direction={{base: 'column', sm: 'row'}} justify="space-between">
        //             <Box>
        //                 <Heading size='md'>
        //                     Your Profile
        //                 </Heading>
        //             </Box>
        //             {/*<Button alignSelf="start">Save</Button>*/}
        //         </Stack>
        //         <Divider/>
        //         <Stack spacing="5" divider={<StackDivider/>}>
        //             <FormControl id="name">
        //                 <Stack
        //                     direction={{base: 'column', md: 'row'}}
        //                     spacing={{base: '1.5', md: '8'}}
        //                     justify="space-between"
        //                 >
        //                     <FormLabel variant="inline">Name</FormLabel>
        //                     <Input maxW={{md: '3xl'}} defaultValue="" placeholder="Your name"/>
        //                 </Stack>
        //             </FormControl>
        //             <FormControl id="email">
        //                 <Stack
        //                     direction={{base: 'column', md: 'row'}}
        //                     spacing={{base: '1.5', md: '8'}}
        //                     justify="space-between"
        //                 >
        //                     <FormLabel variant="inline">Email</FormLabel>
        //                     <Input type="email" maxW={{md: '3xl'}} defaultValue={user.email}/>
        //                 </Stack>
        //             </FormControl>
        //             <FormControl id="picture">
        //                 <Stack
        //                     direction={{base: 'column', md: 'row'}}
        //                     spacing={{base: '1.5', md: '8'}}
        //                     justify="space-between"
        //                 >
        //                     <FormLabel variant="inline">Photo</FormLabel>
        //                     <Stack
        //                         spacing={{base: '3', md: '5'}}
        //                         direction={{base: 'column', sm: 'row'}}
        //                         width="full"
        //                         maxW={{md: '3xl'}}
        //                     >
        //
        //                         <Box position='relative'>
        //                             <Avatar size="lg" name={user.email} position='absolute'/>
        //
        //                             <AssetDropzone onFileDrop={() => console.log("Implement me")} bg='transparent' border={0} boxSize='65px'>
        //                                 <VStack spacing="1">
        //                                     <Square size="16" bg="bg.subtle" borderRadius="lg" bg='transparent'
        //                                             rounded='full' color="transparent"
        //                                             _hover={{color: "black", bg: "blackAlpha.400"}}>
        //                                         <Icon as={IoPencil} boxSize="6"/>
        //                                     </Square>
        //                                 </VStack>
        //                             </AssetDropzone>
        //
        //                         </Box>
        //
        //                     </Stack>
        //                 </Stack>
        //             </FormControl>
        //             <Flex direction="row-reverse">
        //                 <Button bg='yellow.300' _hover={{ bg: 'yellow.100' }}>Save</Button>
        //             </Flex>
        //         </Stack>
        //     </Stack>
        // </Container>
    )
}