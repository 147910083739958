import {
    IconButton,
} from '@chakra-ui/react'
import {Link} from "react-router";
import {IoArrowBackOutline} from "react-icons/io5";

export const BackButton = ({product}) => {
    return (
        <Link to='/products'>
            <IconButton
                aria-label='Back'
                icon={<IoArrowBackOutline color='white' size={20}/>}
                boxSize={10}
                bg='transparent'
                _hover={{}}
            />
        </Link>
    )
}