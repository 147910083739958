import {
    Box,
    Stack,
} from '@chakra-ui/react'
import {BackButton} from "./back-button";
import {getProduct} from "@krfts/app-data"
import {ProductData} from "./product-data";
import {useQuery} from "@tanstack/react-query";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {useEffect} from "react";

import axios from "axios";

export const ProductDetails = ({id}) => {

    const {isPending, isError, data, error} = useQuery({
        queryKey: ['product', id],
        queryFn: () => getProduct(id),
        refetchInterval: (data) => {
            if (
                (data.state.data && data.state.data.model_3D_generation.status !== "DONE") ||
                (data.state.data && data.state.data.lora_training.status !== "DONE")
            ) {
                // console.log("polling")
                return 8000
            } else {
                // console.log("not polling")
                return false
            }
        }
    })

    return (
        <Box
            maxW="7xl"
            mx="auto"
            px={{base: '4', md: '8', lg: '12'}}
            py={{base: '6', md: '8', lg: '6'}}
            color='white'
            bg='black'
            mt={20}
        >
            <Stack direction='column' spacing={8} align='left'>
                <BackButton/>
                <Stack
                    direction={{base: 'column-reverse', lg: 'row'}}
                    spacing={{base: '6', lg: '12', xl: '16'}}
                >

                    {!isPending ?
                        <ProductData product={data}/>
                        :
                        <LoadingIndicator/>
                    }
                </Stack>
            </Stack>
        </Box>

    )
}