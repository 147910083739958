import {
    FormControl,
    FormLabel,
    Checkbox,
    HStack,
    Input,
    Select,
    Icon,
    Box,
    Stack,
    Text,
    Menu,
    MenuItem,
    MenuButton, IconButton,
    Button,
    MenuList,
} from '@chakra-ui/react'
import {IoLayersOutline, IoChevronDown} from "react-icons/io5";
import {useProductsStore} from "../../../../state/products-store";

export const GridOptions = () => {

    const bundleIterations = useProductsStore((state) => state.bundleIterations);
    const setBundleIterations = useProductsStore((state) => state.setBundleIterations);

    return (
        <Stack direction='row' align='center' spacing={4}>
            <Checkbox
                fontWeight={900}
                colorScheme='yellow'
                defaultChecked={bundleIterations}
                onChange={(e) => setBundleIterations(e.target.checked)}
            >
                Bundle Iterations
            </Checkbox>
        </Stack>
    )
}