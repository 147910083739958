import {HStack} from '@chakra-ui/react'
import {IoSquareOutline, IoTabletLandscapeOutline, IoTabletPortraitOutline} from "react-icons/io5";
import {FormatOption} from "./format-option";
import {useStudioStore} from "../../../state";
import {mapImageFormat} from "@krfts/core-utils";
import {useEffect} from "react";

export const ImageFormat = () => {

    const imageFormat = useStudioStore((state) => state.imageFormat)
    const setImageFormat = useStudioStore((state) => state.setImageFormat)

    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)

    useEffect(() => {
        if (lockedGeneration) setImageFormat(mapImageFormat(lockedGeneration.format))
    }, [lockedGeneration]);

    return (
        <>
            <HStack gap={12}>
                <FormatOption format={lockedGeneration ? mapImageFormat(lockedGeneration.format) : imageFormat} name='Square' onClick={() => {
                    if (!lockedGeneration) setImageFormat("square")
                }}>
                    <IoSquareOutline/>
                </FormatOption>

                <FormatOption format={lockedGeneration ? mapImageFormat(lockedGeneration.format) : imageFormat} name='Landscape' onClick={() => {
                    if (!lockedGeneration) setImageFormat("landscape")
                }}>
                    <IoTabletLandscapeOutline/>
                </FormatOption>

                <FormatOption format={lockedGeneration ? mapImageFormat(lockedGeneration.format) : imageFormat} name='Portrait' onClick={() => {
                    if (!lockedGeneration) setImageFormat("portrait")
                }}>
                    <IoTabletPortraitOutline/>
                </FormatOption>

            </HStack>

        </>
    )
}