import { ButtonGroup, IconButton } from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoLogoInstagram, IoLogoLinkedin, IoLogoPinterest } from "react-icons/io5";

export const SocialMediaSection = (props) => (
    <ButtonGroup mt={-6} variant="ghost" {...props}>
        <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            color="gray.400"
            icon={<IoLogoLinkedin fontSize="20px" />}
        />
        <IconButton
            as="a"
            href="#"
            aria-label="Instagram"
            color="gray.400"
            icon={<IoLogoInstagram fontSize="20px" />}
        />
        <IconButton
            as="a"
            href="#"
            aria-label="Pinterest"
            color="gray.400"
            icon={<IoLogoPinterest fontSize="20px" />}
        />
    </ButtonGroup>
);