import {CoreModal} from "@krfts/core-modal";
import {termsOfUse} from "./terms-of-use";
import {VStack, Box, chakra, Heading, Text} from "@chakra-ui/react";
import React from "react";

export const TermsOfUseModal = ({isOpen, onClose}) => {

    return (
        <CoreModal
            isOpen={isOpen}
            onCloseClicked={() => {
                onClose(false)
            }}
            size='4xl'
            title='Terms of Use'
            // hideHeader
        >
            <VStack bg='black' color='white' p={6} spacing={4}>
                <Heading as='h1' size='md'>Terms of Use</Heading>
                <Text><chakra.strong>Last Updated:</chakra.strong> {termsOfUse.lastUpdated}</Text>
                {termsOfUse.sections.map((section, index) => (
                    <Box key={index}>
                        <Heading as='h2' size='md' style={{ textDecoration: "underline" }}>{section.title}</Heading>
                        <Text>
                            {section.content.split("\\n").map((line, i) => (
                                <chakra.span key={i}>
                                    {line}
                                    <br />
                                </chakra.span>
                            ))}
                        </Text>
                    </Box>
                ))}
            </VStack>
        </CoreModal>
    )
}