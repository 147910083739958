import { create } from 'zustand'

export const useProductsStore = create((set) => ({
    showGenerationsDisplayModal: false,
    setShowGenerationsDisplayModal: (val) => set({showGenerationsDisplayModal: val}),

    selectedGeneration: null,
    setSelectedGeneration: (generation) => set({selectedGeneration: generation}),

    bundleIterations: true,
    setBundleIterations: (bundleIterations) => set({bundleIterations: bundleIterations}),

    filterBy: "All",
    setFilterBy: (filterBy) => set({filterBy: filterBy}),
}))