import {CoreModal} from "@krfts/core-modal";
import {useAuthStore} from "@krfts/app-state";
import {SignIn} from "./ui/sign-in";
import {SignUp} from "./ui/sign-up";

export const AuthModal = () => {

    const showAuthModal = useAuthStore((state) => state.showAuthModal);
    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal);
    const authModalMode = useAuthStore((state) => state.authModalMode);

    return (
        <CoreModal
            isOpen={showAuthModal}
            onCloseClicked={() => {
                setShowAuthModal(false)
            }}
            size='xl'
            title={authModalMode === 'signIn' ? 'Sign In' : 'Sign Up'}
            // hideHeader
        >
            {
                authModalMode === 'signIn'
                    ?
                    <SignIn/>
                    :
                    <SignUp/>
            }
        </CoreModal>
    )
}