import { Radio, RadioGroup, useRadio, Box } from '@chakra-ui/react'

export const PlansToggle = (props) => {
    const { getInputProps, getRadioProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderColor='gray.600'
                _checked={{
                    color: 'white',
                    borderColor: 'white',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                color='gray.600'
                px={2}
                py={3}
                w='107px'
                align='center'
            >
                {props.children}
            </Box>
        </Box>
    )
}