import {
    Box,
    IconButton,
    useBreakpointValue,
    List,
    ListItem,
} from '@chakra-ui/react'
import { IoChevronBack, IoChevronForward  } from "react-icons/io5";
import Slider from 'react-slick'
import {useState, Children, useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useProductsStore} from "@krfts/app-products-content/state/products-store";
import {useStudioStore} from "@krfts/app-studio-content/state";

export const GenerationContainer = ({children}) => {

    const bundleIterations = useProductsStore((state) => state.bundleIterations);
    const inpaintingImage = useStudioStore((state) => state.inpaintingImage)

    const shouldDisplayLockedGeneration = useStudioStore((state) => state.shouldDisplayLockedGeneration);
    const setShouldDisplayLockedGeneration = useStudioStore((state) => state.setShouldDisplayLockedGeneration);

    const generationCurrentlyRunning = useStudioStore((state) => state.generationCurrentlyRunning);
    const setGenerationCurrentlyRunning = useStudioStore((state) => state.setGenerationCurrentlyRunning);


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        lazyLoad: true,
        draggable: !inpaintingImage,
        appendDots: dots => (
            <Box
                position='absolute'
                bottom={0}
            >
                <List bgGradient='linear(to-b, transparent, whiteAlpha.300)'>{dots}</List>
            </Box>
        ),
    }

    const [slider, setSlider] = useState(null)

    const top = useBreakpointValue({base: '90%', md: '50%'})
    const side = useBreakpointValue({base: '30%', md: '15px'})

    useEffect(() => {
        Children.map(children, (child, index) => {
            if (child.props?.generationId === generationCurrentlyRunning) {
                slider?.slickGoTo(index, false);
                setGenerationCurrentlyRunning(null)
            }
        })
    }, [children, generationCurrentlyRunning, slider]);

    useEffect(() => {
        if (shouldDisplayLockedGeneration) {
            slider?.slickGoTo(0, false);
            setShouldDisplayLockedGeneration(false)
        }
    }, [shouldDisplayLockedGeneration]);

    return (
        <Box position='relative' height='100%' width='100%' overflow='hidden' bg='black'>

            {Children.count(children) > 1 && bundleIterations ? <Box>
                <IconButton
                    color='gray.400'
                    _hover={{color: "white"}}
                    aria-label="left-arrow"
                    variant="ghost"
                    position="absolute"
                    left={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}>
                    <IoChevronBack size="30px"/>
                </IconButton>
                <IconButton
                    color='gray.400'
                    _hover={{color: "white"}}
                    aria-label="right-arrow"
                    variant="ghost"
                    position="absolute"
                    right={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={6}
                    onClick={() => slider?.slickNext()}>
                    <IoChevronForward  size="30px"/>
                </IconButton>
            </Box> : null}

            <Slider {...settings} ref={(slider) => setSlider(slider)}>
                {children}
            </Slider>
        </Box>

    )
}