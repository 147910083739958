import {createUser} from "./auth-api";
import {useAuthStore} from "@krfts/app-state";


export const handleSignIn = async (email, password) => {
    try {
        const result = await useAuthStore.getState().signIn({email, password})
        console.log("signin result:", result)
        return result
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleSignInWithGoogle = async () => {
    try {
        await useAuthStore.getState().signInWithGoogle()
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleSignUp = async (email, password) => {
    const authStore = useAuthStore.getState()
    try {
        await authStore.signUp({email, password})
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleResetPassword = async (email) => {
    const result = await useAuthStore.getState().resetPassword(email)
}