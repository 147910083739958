import {Box, Link, SimpleGrid, Stack} from "@chakra-ui/react";
import {FooterHeading} from "./footer-heading";
import {TermsOfUseModal} from "@krfts/app-legals"
import {PrivacyPolicyModal} from "@krfts/app-legals";
import {useState} from "react";

export const LinkSection = (props) => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsOfUse, setShowTermsOfUse] = useState(false);

    return(<>
        <SimpleGrid columns={2} {...props} textColor="#fcfcfc" fontSize='xs'>
            <Box minW="130px">
                {/*<FooterHeading mb="4">Product</FooterHeading>*/}
                <Stack>
                    <Link>About Imaginate</Link>
                    <Link>How to use</Link>
                    {/*<Link>For brands</Link>*/}
                </Stack>
            </Box>
            <Box minW="130px">
                {/*<FooterHeading mb="4">Legal</FooterHeading>*/}
                <Stack>
                    <Link onClick={() => setShowPrivacyPolicy(true)}>Privacy</Link>
                    <Link onClick={() => setShowTermsOfUse(true)}>Terms of Use</Link>
                    {/*<Link>License</Link>*/}
                </Stack>
            </Box>
        </SimpleGrid>
        <TermsOfUseModal isOpen={showTermsOfUse} onClose={() => setShowTermsOfUse(false)}/>
        <PrivacyPolicyModal isOpen={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}/>
    </>
    )
}